import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Navbar from '../../components/NavBar/navbar.js';
import Footer from '../../components/Footer/footer.js';
import Loading from '../../components/Loading/loading.js';

import './style.css'

import { getAccount, searchAccount } from '../../data/account-data-access.js';
import { getFriendship, createFriendRequest, approveFriendRequest, rejectFriendRequest, removeFriend } from '../../data/friend-data-access.js';

const Profile = (props) => {
    return (
        <div className="profile-content">
            <div className="profile-content-wrapper">
                <Navbar />
                <ProfileBody />
                <Footer />
            </div>
        </div>
    )
}

const ProfileBody = (props) => {
    const { user, isAuthenticated, isAuthLoading } = useAuth0();
    const [searchParams, setSearchParams] = useSearchParams();
    const [username, setUsername] = useState('');
    const [loadedProfile, setLoadedProfile] = useState();
    const [activeUser, setActiveUser] = useState();
    const [notFound, setNotFound] = useState(false);
    const [loadingUser, setLoadingUser] = useState(false);
    const [loadingProfile, setLoadingProfile] = useState(false);

    useEffect(() => {
        const load = async () => {
            setLoadingProfile(true);
            await getProfileFromParams();
            setLoadingProfile(false);
        }

        load();

    }, [searchParams]);

    useEffect(() => {
        const load = async () => {
            setLoadingUser(true);
            await getUser();
            setLoadingUser(false);
        }

        load();
    }, [user]);

    const getProfileFromParams = async () => {
        var username = searchParams.get('username');
        setUsername(username);

        return await loadUserAccount(username);
    }

    const loadUserAccount = async (username) => {
        var res = await searchAccount(username)

        if (!res || res.length === 0) {
            setNotFound(true);
            return null;
        }

        setLoadedProfile(res[0]);
        return res[0];
    }

    const getUser = async () => {
        if (!isAuthenticated || isAuthLoading)
            return;

        var res = await getAccount(user.email);

        setActiveUser(res);

        return res;
    }

    //definitely improve this at some point
    if (notFound || !loadedProfile) {
        return (
            <span> We couldn't find that user, please try again </span>
        )
    }

    if (loadingUser || loadingProfile) {
        return <Loading />
    }

    return (
        <div className="profile-body-container">
            <div className="profile-body-header">
                <img
                    alt="image"
                    src="https://play.teleporthq.io/static/svg/default-img.svg"
                    className="profile-image"
                />
                <div className="profile-header-stack">
                    <p className="profile-header-name">{loadedProfile?.DisplayName ?? ""}</p>
                    <FriendButtonContainer activeUser={activeUser} profile={loadedProfile} />
                </div>
            </div>
            <p className="profile-header-bio">{loadedProfile?.Bio ?? ""}</p>

        </div>
    )
}

const FriendButtonContainer = (props) => {
    const [friendship, setFriendship] = useState();

    useEffect(() => {
        getFriendshipStatus(props.activeUser?.Id, props.profile?.Id);
    }, []);

    const getFriendshipStatus = async (accountId, friendId) => {
        if (!accountId || !friendId || accountId === friendId) {            
            return;
        }

        var res = await getFriendship(accountId, friendId);

        setFriendship(res);
    }

    const sendRequest = async () => {
        await createFriendRequest(props.activeUser?.Id, props.profile?.Id);
        await getFriendshipStatus(props.activeUser?.Id, props.profile?.Id);
    }

    const approveRequest = async () => {
        await approveFriendRequest(props.activeUser?.Id, props.profile?.Id);
        await getFriendshipStatus(props.activeUser?.Id, props.profile?.Id);
    }

    const rejectRequest = async () => {
        await rejectFriendRequest(props.activeUser?.Id, props.profile?.Id);
        await getFriendshipStatus(props.activeUser?.Id, props.profile?.Id);
    }

    const removeFriendship = async () => {
        await removeFriend(props.activeUser?.Id, props.profile?.Id);
        await getFriendshipStatus(props.activeUser?.Id, props.profile?.Id);
    }

    //user viewing their own profile -- we probably ought to show an edit button here instead
    if (props.profile?.Id === props.activeUser?.Id) {
        return (
            <div></div>
        )
    }

    //active request to user - show accept/decline
    if (friendship?.Status?.Id === 0 && friendship?.RequestedBy !== props.activeUser?.Id) {
        return (
            <div className="friend-button-container">
                <button className="popup-item-button"
                    onClick={() => approveRequest()}>
                    Accept
                </button>
                <button className="popup-item-button"
                    onClick={() => rejectRequest()}>
                    Decline
                </button>
            </div>
        )
    }

    //active request from user - show pending
    if (friendship?.Status?.Id === 0 && friendship?.RequestedBy === props.activeUser?.Id) {
        return (
            <div>
                <button className="popup-item-button"
                        disabled={true}>
                        Pending
                </button>
            </div>
        )
    }

    //active friend - show remove option
    if (friendship?.Status?.Id === 1) {
        return (
            <div>
                <button className="popup-item-button"
                    onClick={() => removeFriendship()}>
                    Remove Friend
                </button>
            </div>
        )
    }

    //rejected or not friends - show send friend request
    return (
        <div>
            <button className="popup-item-button"
                onClick={() => sendRequest()}>
                Add Friend
            </button>
        </div>
    )
}

export default Profile
