import React, { useState } from "react";

import Popup from '../Popup/popup.js';
import WaitlistComponent from './waitlist.js';
import './style.css';

const WaitlistPopup = (props) => {
    const [popupOpen, setPopupOpen] = useState(false);

    return (
        <div>
            <button className="button" onClick={() => setPopupOpen(true)}>Join Waitlist</button>
            <Popup trigger={popupOpen} setTrigger={setPopupOpen}>
                <div className="waitlistPopup-container">
                    <div className="waitlistPopup-container1">
                        <WaitlistComponent rootClassName="waitlist-root-class-name"></WaitlistComponent>
                    </div>
                </div>
            </Popup>
        </div>
    )
}

export default WaitlistPopup