import React, { useState } from 'react'
import { NavLink } from "react-router-dom";

import './style.css'

const Login = (props) => {
    const [email, setEmailState] = useState('');
    const [password, setPasswordState] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [usernameErrors, setUsernameErrors] = useState(''); 
    const [passwordErrors, setPasswordErrors] = useState(''); 

    //this url should be in a config
    const login = async (firstLastName, email) => {

        if (!email) {
            setUsernameErrors("Username cannot be empty");
            return;
        }

        if (!password) {
            setPasswordErrors("Password cannot be empty");
            return;
        }

        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            setUsernameErrors("Email is not valid");
            return;
        }

        let salt = await fetch("login/" + email + "/salt");
        let hashedPassword = "";//bcrypt.hashSync(password, )

        if (!salt) {
            console.log("failed to get user salt");
            return;
        }

        let authResponse = await fetch('login', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                UserName: email,
                Password: hashedPassword,
            }),
        })

        if (!authResponse.status == 200) {
            console.log('authentication failed');
            return;
        }

        setUsernameErrors("");
        setPasswordErrors("");
    }

    return (
        <div className="login-container">
            <div className="login-container1">
                <span className="login-text">{props.text1}</span>
                <div className="login-options-wrapper">
                    <div className={`social-login-container-${props.rootClassName}`}></div>
                    <div className="login-separator"></div>
                    <div className={`login-container2-${props.rootClassName}`}>
                        <div className="email-submission-container">
                            <input
                                type="text"
                                placeholder={props.textinput_username}
                                className="input"
                                onChange={(event) => {
                                    setEmailState(event.target.value);
                                }}
                                value={email}
                            />
                            <span className="email-submission-errors">{usernameErrors}</span>
                        </div>
                        <div className="password-submission-container">
                            <input
                                type="text"
                                placeholder={props.textinput_password}
                                className="input"
                                onChange={(event) => {
                                    setPasswordState(event.target.value);
                                }}
                                type={
                                    showPassword ? "text" : "password"
                                }
                                value={password}
                            />
                            <span className="email-submission-errors">{passwordErrors}</span>
                        </div>
                        <div className="email-login-container">
                            <NavLink className="forgot-password-text" to="/login-help" >
                                Forgot Password?
                            </NavLink>
                            <button className="login-button button"
                                onClick={() => login('NULL', email)}>
                                Login
                            </button>
                        </div>
                    </div>
                </div>
                <span>
                    Don't Have an account? &#160;
                        <NavLink className="link-text" to="/register" >
                        Register Now
                        </NavLink>
                </span>
            </div>
        </div>
    )
}

export default Login

