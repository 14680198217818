import axios from 'axios';

async function getAccountNotifications(accountId, read) {
    try {
        var res = await axios.get("api/notification", {
            params: {
                accountId: accountId,
                read: read
            }
        });
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

async function updateNotification(notification) {
    try {
        var res = await axios.put("api/notification/", notification);
        console.log(res);

        return true;
    }
    catch (exception) {
        console.log(exception);
        return false;
    }
}

export { getAccountNotifications, updateNotification }