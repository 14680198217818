import React from 'react';
import Navbar from '../../components/NavBar/navbar.js';
import LoginComponent from '../../components/Login/login.js';
import Footer from '../../components/Footer/footer.js';
import './style.css'

const Login = (props) => {
    return (
        <div className="loginScreen-container">
            <div className="loginScreen-container1">
                <Navbar></Navbar>
                <LoginComponent rootClassName="login-root-class-name"></LoginComponent>
                <Footer rootClassName="footer-root-class-name1"></Footer>
            </div>
        </div>
    )
}

export default Login
