import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import './style.css'

const ProtectedRoute = ({ component, ...args }) => {
    const ProtectedComponent = withAuthenticationRequired(component, args);
    return <ProtectedComponent />;
};

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
        <Routes>
            {AppRoutes.map((route, index) => {
                const { element, isProtected, ...rest } = route;
                return isProtected
                    ? <Route key={index} {...rest} element={<ProtectedRoute component={() => element} />} />
                    : <Route key={index} {...rest} element={element} />;
            })}
        </Routes>
    );
  }
}
