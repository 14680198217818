import React from 'react';
import Navbar from '../../components/NavBar/navbar.js';
import WaitlistComponent from '../../components/Waitlist/waitlist.js';
import Footer from '../../components/Footer/footer.js';
import './style.css'

const Waitlist = (props) => {
    return (
        <div className="waitlistScreen-container">
            <div className="waitlistScreen-container1">
                <Navbar></Navbar>
                <WaitlistComponent rootClassName="waitlist-root-class-name"></WaitlistComponent>
                <Footer rootClassName="footer-root-class-name1"></Footer>
            </div>
        </div>
    )
}

export default Waitlist