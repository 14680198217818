import axios from 'axios';

async function getAccount(email, id) {
    var res = await axios.get("api/account?", {
        params: {
            email: email,
            id: id
        }
    })

    console.log(res);

    return res.data;
}

async function searchAccount(username) {
    try {
        var res = await axios.get("api/account/search?", {
            params: {
                username: username
            }
        })

        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return null;
    }
}

async function updateAccount(account) {
    try {
        var res = await axios.put('api/account', account)

        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return null;
    }
}

export { searchAccount, getAccount, updateAccount }