import React from 'react';
import { useNavigate } from "react-router-dom";
import SidebarButton from './sidebar-button.js';

import './style.css';

const Sidebar = (props) => {
    const navigate = useNavigate();

    return (
        <div className="sidebar">
            <SidebarButton action={() => {
                navigate('/Chat')
            }}
                navTitle="Chat"
                isSelected={props.chatSelected ?? false} />
            <SidebarButton action={() => {
                navigate('/Itineraries')
            }}
                navTitle="Itineraries"
                isSelected={props.itinerariesSelected ?? false} />
            <SidebarButton action={() => {
                navigate('/Votes')
            }}
                navTitle="Votes"
                isSelected={props.votesSelected ?? false} />
        </div>
    )
}

export default Sidebar