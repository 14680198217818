import React, { useState }  from 'react'
import Navbar from '../../components/NavBar/navbar.js'
import Footer from '../../components/Footer/footer.js'
import Contact from '../../components/Contact/contact.js';
import './style.css'

const Legal = (props) => {
    const termsAndConditions = {
        title: "Terms and Conditions",
        body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              bibendum orci in sem s tempus.Pellentesque a eleifend
              purus.Duis euismod suscipit neque, vitae maximus diam ornare
              vitae.Sed laoreet dolor mauris, et condimentum dui dictum id.Ut
              vitae erat tristique, bibendum dui eget, sodales tortor.
              Pellentesque dapibus, orci quis bibendum egestas, diam ipsum
              dapibus erat, at varius justo ligula eget leo.Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Etiam eget commodo justo.`
    };

    const privacyStatement = {
        title: "Privacy Statement",
        body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              bibendum orci in sem s tempus.Pellentesque a eleifend
              purus.Duis euismod suscipit neque, vitae maximus diam ornare
              vitae.Sed laoreet dolor mauris, et condimentum dui dictum id.Ut
              vitae erat tristique, bibendum dui eget, sodales tortor.
              Pellentesque dapibus, orci quis bibendum egestas, diam ipsum
              dapibus erat, at varius justo ligula eget leo.Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Etiam eget commodo justo.`
    };

    const cookieStatement = {
        title: "Cookie Usage",
        body:`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              bibendum orci in sem s tempus.Pellentesque a eleifend
              purus.Duis euismod suscipit neque, vitae maximus diam ornare
              vitae.Sed laoreet dolor mauris, et condimentum dui dictum id.Ut
              vitae erat tristique, bibendum dui eget, sodales tortor.
              Pellentesque dapibus, orci quis bibendum egestas, diam ipsum
              dapibus erat, at varius justo ligula eget leo.Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Etiam eget commodo justo.`
    };

    let hiddenStyle = (hidden) => {
        return {
            display: hidden ? "flex" : "none"
        }
    }

    var [legalText, setLegalText] = useState(termsAndConditions);
    var [tcSelected, setTCSelected] = useState(true);
    var [cookieSelected, setCookieSelected] = useState(false);
    var [privacySelected, setPrivacySelected] = useState(false);
    var [contactSelected, setContactSelected] = useState(false);

    const setSelectedSection = (toSet) => {
        setTCSelected(false);
        setCookieSelected(false);
        setPrivacySelected(false);
        setContactSelected(false);
        toSet();
    }

    return (
        <div className="legal-container">
            <Navbar rootClassName="legal-navbar"></Navbar>
            <div className="legal-container1">
                <div className="legal-sidebar">
                    <nav className="legal-nav">
                        <LegalNavButton action={() => {
                                setLegalText(termsAndConditions);
                                setSelectedSection(() => setTCSelected(true));
                            }}
                            navTitle="Terms &amp; Conditions"
                            isSelected={tcSelected}/>
                        <LegalNavButton action={() => {
                                setLegalText(privacyStatement);
                                setSelectedSection(() => setPrivacySelected(true));
                            }}
                            navTitle="Privacy"
                            isSelected={privacySelected} />
                        <LegalNavButton action={() => {
                                setLegalText(cookieStatement);
                                setSelectedSection(() => setCookieSelected(true));
                            }}
                            navTitle="Cookies"
                            isSelected={cookieSelected} />
                        <LegalNavButton action={() => {
                                setLegalText(termsAndConditions);
                                setSelectedSection(() => setContactSelected(true));
                            }}
                            navTitle="Contact"
                            isSelected={contactSelected} />
                    </nav>
                </div>
                <div className="legal-container2" style={hiddenStyle(!contactSelected)}>
                    <span className="legal-body-text-container">
                        <h1 className="legal-body-title">{legalText.title}</h1>
                        <span className="legal-body">
                            {legalText.body}
                        </span>
                    </span>
                </div>
                <div className="contact-container" style={hiddenStyle(contactSelected)}>
                    <Contact/>
                </div>
            </div>
            <Footer rootClassName="footer-root-class-name4"></Footer>
        </div>
    )
}

const LegalNavButton = (props) => {
    let selectedStyle = {
        background: props.isSelected ? "var(--dl-color-gray-600)" : "none"
    }

    return (
        <button className="legal-nav-text-container"
            onClick={props.action}
            style={selectedStyle}>
            {props.navTitle}
        </button>
    )
}

export default Legal
