import axios from 'axios';

async function getChannelMessages(channelId, pageIndex) {
    try {
        var res = await axios.get("api/chat?", {
            params: {
                channelId: channelId,
                pageOffset: pageIndex,
                rowsPerPage: 50
            }
        });
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

async function getLinkPreview(url) {
    try {
        var res = await axios.get("api/chat/LinkPreview?", {
            params: {
                url: url
            }
        });
        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return null;
    }
}

async function markChatAsRead(chatId, accountId) {
    var res = await axios.put("api/chat/" + chatId,
        {
            accountId: accountId
        })

    console.log(res);
    return res.data;
}

export { getChannelMessages, getLinkPreview, markChatAsRead }
