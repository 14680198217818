import React, { useState, useEffect } from 'react';
import './style.css';

const LoadingAnimation = (props) => {
    return (
        <div className="paperplane">
            <div className="plane">
                <div className="wingRight"></div>
                <div className="wingLeft"></div>
                <div className="bottom"></div>
                <div className="top"></div>
                <div className="middle"></div>
            </div>
            <div className="clouds">
                <div className="cloudOne"></div>
                <div className="cloudTwo"></div>
                <div className="cloudThree"></div>
                <div className="cloudFour"></div>
                <div className="cloudFive"></div>
                <div className="cloudSix"></div>

            </div>
        </div>
    );
}

const Loading = (props) => {
    let [loadingText, setLoadingText] = useState("Preparing the ultimate trip planning experience...");
    let [currentLoadedTextIndex, setLoadedTextIndex] = useState(0);

    let loadingTextOptions = [
        "Looking for the best deals...",
        "Consulting with the experts...",
        "Getting permission from your mom...",
        "Checking the weather...",
        "Discussing spring break plans with the interns...",
        "Tracking Elon's jet...",
        "Packing my bag..."
    ]

    useEffect(() => {
        let interval = setInterval(() => {
            setLoadingText(loadingTextOptions[currentLoadedTextIndex]);

            setLoadedTextIndex(currentLoadedTextIndex + 1);

            if (currentLoadedTextIndex >= loadingTextOptions.length - 1)
                setLoadedTextIndex(0);

        }, 1550);

        //Clearing the interval
        return () => clearInterval(interval);
    });

    return (
        <div className="loading-container">
            <LoadingAnimation />
            <p className="loading-text">{loadingText}</p>
        </div>
    );
}

export default Loading;