import axios from 'axios';

async function getGroups(accountId) {
    var res = await axios.get("api/group/" + accountId);
    console.log(res);

    return res.data;
}

async function createGroup(accountId, groupName) {
    var res = await axios.post("api/group/",
        {
            AccountId: accountId,
            GroupName: groupName
        })
    console.log(res);

    return res.data;
}

async function addGroupMember(accountId, groupId) {
    var res = await axios.put("api/group/" + groupId,
        {
            AccountId: accountId
        })
    console.log(res);

    return res.data;
}

async function removeGroupMember(accountId, groupId) {
    var res = await axios.delete("api/group/" + groupId + "/member/" + accountId)
    console.log(res);

    return res.data;
}

export { getGroups, createGroup, addGroupMember, removeGroupMember }