import React from 'react';
import Navbar from '../../components/NavBar/navbar.js';
import ContactComponent from '../../components/Contact/contact.js';
import Footer from '../../components/Footer/footer.js';
import './style.css'

const Contact = (props) => {
    return (
        <div className="contactScreen-container">
            <div className="contactScreen-container1">
                <Navbar></Navbar>
                <ContactComponent rootClassName="contact-root-class-name"></ContactComponent>
                <Footer rootClassName="footer-root-class-name1"></Footer>
            </div>
        </div>
    )
}

export default Contact