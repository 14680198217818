import React from 'react'
import './style.css';

const SidebarButton = (props) => {
    let selectedStyle = {
        background: props.isSelected ? "var(--dl-color-gray-600)" : "none"
    }

    return (
        <button className="sidebar-button-container"
                onClick={props.action}
                style={selectedStyle}>
                {props.navTitle}
        </button>
    )
}

export default SidebarButton