import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { HubConnectionBuilder, LogLevel, HttpTransportType } from "@microsoft/signalr";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";

import Navbar from '../../components/NavBar/navbar.js';
import Footer from '../../components/Footer/footer.js';
import Sidebar from '../../components/Sidebar/sidebar.js';
import Loading from '../../components/Loading/loading.js';
import './style.css'

import { getChannelMessages, getLinkPreview, markChatAsRead } from '../../data/chat-data-access.js';
import { getGroups, createGroup, addGroupMember, removeGroupMember } from '../../data/group-data-access.js';
import { getChannels, createChannel, updateChannelName, addChannelMember, removeChannelMember } from '../../data/channel-data-access.js';
import { getAccount } from '../../data/account-data-access.js';

const Chats = (props) => {
    const { user, isAuthenticated, isAuthLoading } = useAuth0();
    const [searchParams, setSearchParams] = useSearchParams();
    const [connection, setConnection] = useState();
    const [channels, setChannels] = useState();
    const [selectedChannel, setSelectedChannel] = useState();
    const [account, setAccount] = useState();
    const [refreshNeeded, setRefreshNeeded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const load = async () => {
            var loadedAccount = await getUser();

            if (!loadedAccount)
                return;

            await configSocket(loadedAccount.Id);
            await loadChannels(loadedAccount.Id);
        }

        load();
    }, [user]);

    useEffect(() => {
        const refresh = async () => {
            var loadedChannels = await getChannels(account.Id);

            if (selectedChannel) {
                var freshIdx = loadedChannels.findIndex((channel) => channel.Id === selectedChannel.Id);
                setSelectedChannel(loadedChannels[freshIdx]);
            }

            setChannels(loadedChannels);
            setRefreshNeeded(false);
        }

        if (!account || !account?.Id || !refreshNeeded)
            return;

        refresh();
    }, [refreshNeeded]);

    useEffect(() => {
        if (channels)
            setIsLoading(false);

    }, [channels])

    useEffect(() => {
        if (!selectedChannel)
            return;

        setSearchParams({
            channel: selectedChannel.Id
        })
    }, [selectedChannel])

    const configSocket = async (id) => {
        const socketConnection = new HubConnectionBuilder()
            .configureLogging(LogLevel.Debug)
            .withUrl("/chat", {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .build();
        await socketConnection.start();
        setConnection(socketConnection);

        socketConnection.invoke("connected", id)

        socketConnection && socketConnection.on("message", receiveMessage);
    };

    const loadChannels = async (accountId) => {
        var res = await getChannels(accountId);
        setChannels(res);

        if (searchParams) {
            var channelIdFromUrl = searchParams.get("channel");
            var idx = res.findIndex((channel) => channel.Id == channelIdFromUrl);
            setSelectedChannel(res[idx]);
        }
    }

    const getUser = async () => {
        if (!isAuthenticated || isAuthLoading)
            return;

        var res = await getAccount(user.email);

        setAccount(res);

        return res;
    }

    const sendMessage = (channelId, message) => {
        let msg = {
            AccountId: account.Id,
            ChannelId: channelId,
            Message: message
        }

        connection && connection.invoke("message", msg)
    }

    const receiveMessage = (message) => {
        setRefreshNeeded(true);
    }

    const addNewChannel = () => {
        const newChannel = {
            ChannelName: "New Channel",
            Chats: [],
            GroupId: 0,
            Id: -1,
            LastMessage: "",
            NewMessageRead: true
        }

        channels.push(newChannel)

        setSelectedChannel(newChannel)
    }

    const chatBody = () => {
        if (isLoading) {
            return (<Loading />)
        }
        else {
            return (
                <div className="chat-group-focused-container3">
                    <ChannelList channels={channels}
                        setSelectedChannel={setSelectedChannel}
                        selectedChannel={selectedChannel}
                        addNewChannel={addNewChannel}
                        searchParams={searchParams} />
                    <MessagesPanel onSendMessage={sendMessage}
                        channel={selectedChannel}
                        user={account}
                        setRefreshNeeded={setRefreshNeeded} />
                </div>
            )
        }
    }

    return (
        <div className="chat-group-focused-container">
            <div className="chat-group-focused-container1">
                <Navbar/>
                <div className="chat-group-focused-container2">
                    <Sidebar chatSelected={true} />
                    {
                        chatBody()
                    }
                </div>
                <Footer/>
            </div>
        </div>
    )
}

const ChannelList = (props) => {
    const [channels, setChannels] = useState(props.channels);

    useEffect(() => {
        setChannels(props.channels);
    }, [props.channels]);

    useEffect(() => {
        if (props.selectedChannel || !props.channels || props.channels.length === 0)
            return;

        props.setSelectedChannel(props.channels[0])
    })

    var hasChannels = channels && channels.length > 0;

    return (
        <div className="chat-group-focused-channels-container">
            <div className="chat-group-focused-channels-header">
                <h1 className="chat-group-focused-text">Chats</h1>
                <div className="chat-group-focused-channel-list-controls">
                    <svg viewBox="0 0 1024 1024"
                        className="chat-group-focused-icon">
                        <path d="M256 554v-84h512v84h-512zM128 256h768v86h-768v-86zM426 768v-86h172v86h-172z"></path>
                    </svg>
                    <div className="chat-group-focused-add-new-chat-container">
                        <svg viewBox="0 0 1024 1024"
                             className="chat-group-focused-icon02"
                             onClick={() => props.addNewChannel()}>
                        <path d="M726 470v-86h-172v-170h-84v170h-172v86h172v170h84v-170h172zM938 170v768l-170-170h-598q-34 0-59-26t-25-60v-512q0-34 25-59t59-25h684q34 0 59 25t25 59z"></path>
                    </svg>
                    </div>

                </div>
            </div>
            <div className="chat-group-focused-channels">
                {
                    !hasChannels &&
                    <span className="no-content-message">There are no channels to show</span>
                }
                {
                    hasChannels &&
                    channels.map((c, idx) => <Channel key={idx}
                                                      channel={c}
                                                      selectedChannel={props.selectedChannel}
                                                      setSelectedChannel={(channel) => props.setSelectedChannel(channel)} />)
                } 
            </div>
        </div>
    )
}

const Channel = (props) => {
    var channelItemStyle = props.channel.LastMessage.Read
        ? "unread"
        : "read";

    const isSelected = props.selectedChannel?.Id === props.channel.Id;

    var lastMessageContent = props.channel.LastMessage;
    const embeddedLink = props.channel.LastMessage?.match(/(?:\(\"(.+)\"\)\[\"((?:https|http)\:\/\/.*\..*)\"\])/) ?? [];

    if (embeddedLink && embeddedLink.length > 0)
        lastMessageContent = props.channel.LastMessage.replace(embeddedLink[0], embeddedLink[1]);

    if (lastMessageContent?.length > 50)
        lastMessageContent = props.channel.LastMessage.slice(0, 50) + "...";

    return (
        <div className="chat-group-focused-channel"
            style={{ backgroundColor: isSelected ? "var(--dl-color-gray-700)" : "unset"} }
             onClick={() => props.setSelectedChannel(props.channel)}>
            <div className="chat-group-focused-chat">
                <div className="chat-group-focused-container4">
                    <h1 className="chat-group-focused-text1">
                        {props.channel.ChannelName}
                    </h1>
                    <span>{lastMessageContent}</span>
                    <svg viewBox="0 0 1024 1024"
                        className="chat-group-focused-icon04">
                        <path d="M250 176l92-90 426 426-426 426-92-90 338-336z"></path>
                    </svg>
                </div>
             
            </div>
        </div>
    )
}

const MessagesPanel = (props) => {
    const [messages, setMessages] = useState(props.channel?.Chats ?? [])
    const [input, setInput] = useState('');
    const [pageIndex, setPageIndex] = useState(0);
    const [singleLineMode, setSingleLineMode] = useState(true);
    const [groupName, setGroupName] = useState(props.channel?.ChannelName)
    const [editingGroupName, setEditingGroupName] = useState(false);
    const firstMessage = useRef(null);
    const newChannelNotSaved = props.channel?.Id === -1;

    useEffect(() => {
        loadChannelMessages();
        setGroupName(props.channel?.ChannelName);
        setInput('');
        setEditingGroupName(false);
        setSingleLineMode(true);
        setPageIndex(0);
    }, [props.channel]);

    useEffect(() => {
        giveGroupNameTextBoxFocus();
    }, [editingGroupName])

    useEffect(() => {
        if (firstMessage.current) {
            firstMessage.current.scrollIntoView({ block: "nearest", inline: "nearest" });
        }
    });

    const loadChannelMessages = async () => {
        if (!props.channel)
            return;

        if (props.channel?.Chats?.length > 0 ?? false) {
            setMessages(props.channel.Chats);
            return;
        }

        var res = await getChannelMessages(props.channel.Id, pageIndex);

        setMessages(res);
        props.channel.Chats = res;
    }

    const handleInput = e => {
        if (e.target.value === '\n' && singleLineMode) {
            return;
        }

        setInput(e.target.value);
    }

    const sendOnEnter = e => {
        if (e.key === 'Enter' && singleLineMode) {
            send();
        }
    }

    const send = () => {
        if (input && input != '') {
            props.onSendMessage(props.channel.Id, input);
            setInput('');
        }
    }

    const toggleEditingGroupName = () => {
        setEditingGroupName(!editingGroupName)
    }

    const handleGroupNameChange = e => {
        if (e.target.value === '\n') {
            return;
        }

        setGroupName(e.target.value)
    }

    const saveGroupNameOnEnter = e => {
        if (e.key === 'Enter') {
            saveGroupName();
        }
    }

    const saveGroupName = async () => {
        if (groupName === props.channel?.ChannelName) {
            setEditingGroupName(false);
            return;
        }

        if (props.channel?.Id === -1) {
            await createChannel(props.user.Id, null, groupName);
        }
        else {
            await updateChannelName(props.channel?.Id, groupName);
        }

        props.setRefreshNeeded(true);
    }

    const giveGroupNameTextBoxFocus = () => {
        var textArea = document.getElementById("edit-group-name-textarea");
        textArea?.focus()
        textArea?.setSelectionRange(textArea.value.length, textArea.value.length);
    }

    let list = <div className="no-content-message">There are no messages to show</div>;
    if (props.channel && messages.length > 0) {
        list = messages.map((chat, index) =>
            <Message key={chat.Id}
                id={chat.Id}
                chat={chat}
                user={props.user}
                ref={index === messages.length - 1 ? firstMessage : null} />
        );
    }

    var groupNameField = <h1 className="chat-group-focused-text3">
                            <span>{props.channel?.ChannelName}</span>
                         </h1>;

    if (editingGroupName) {
        groupNameField = <textarea className="chat-group-focused-edit-group-name-textarea textarea"
            onChange={handleGroupNameChange}
            value={groupName}
            onKeyDown={saveGroupNameOnEnter}
            onBlur={saveGroupName}
            id="edit-group-name-textarea"/>
    }

    return (
        <div className="chat-group-focused-chats">
            <div className="chat-group-focused-channel-tools">
                <div className="chat-group-focused-group-name-container">
                    {
                        groupNameField
                    }
                    <svg viewBox="0 0 1024 1024"
                        className="chat-group-focused-edit-group-name"
                        onClick={() => toggleEditingGroupName()}>
                        <path d="M864 0c88.364 0 160 71.634 160 160 0 36.020-11.91 69.258-32 96l-64 64-224-224 64-64c26.742-20.090 59.978-32 96-32zM64 736l-64 288 288-64 592-592-224-224-592 592zM715.578 363.578l-448 448-55.156-55.156 448-448 55.156 55.156z"></path>
                    </svg>
                </div>
                <div className="chat-group-focused-group-tools-container">
                    <svg viewBox="0 0 1024 1024"
                         className="chat-group-focused-icon06">
                        <path d="M384 597.333c58.923 0 112.256-23.893 150.869-62.507 38.571-38.571 62.464-91.904 62.464-150.827s-23.893-112.256-62.464-150.827c-38.613-38.613-91.947-62.507-150.869-62.507s-112.256 23.893-150.869 62.507c-38.571 38.571-62.464 91.904-62.464 150.827s23.893 112.256 62.464 150.827c38.613 38.613 91.947 62.507 150.869 62.507z"></path>
                        <path d="M384 896c150.101 0 256-42.667 256-85.333 0-85.333-100.437-170.667-256-170.667-160 0-256 85.333-256 170.667 0 42.667 96 85.333 256 85.333z"></path>
                        <path d="M896 512h-85.333v-85.333c0-23.595-19.072-42.667-42.667-42.667s-42.667 19.072-42.667 42.667v85.333h-85.333c-23.595 0-42.667 19.072-42.667 42.667s19.072 42.667 42.667 42.667h85.333v85.333c0 23.595 19.072 42.667 42.667 42.667s42.667-19.072 42.667-42.667v-85.333h85.333c23.595 0 42.667-19.072 42.667-42.667s-19.072-42.667-42.667-42.667z"></path>
                    </svg>
                    <svg viewBox="0 0 1024 1024"
                         className="chat-group-focused-icon10" >
                        <path d="M512 662q62 0 106-44t44-106-44-106-106-44-106 44-44 106 44 106 106 44zM830 554l90 70q14 10 4 28l-86 148q-8 14-26 8l-106-42q-42 30-72 42l-16 112q-4 18-20 18h-172q-16 0-20-18l-16-112q-38-16-72-42l-106 42q-18 6-26-8l-86-148q-10-18 4-28l90-70q-2-14-2-42t2-42l-90-70q-14-10-4-28l86-148q8-14 26-8l106 42q42-30 72-42l16-112q4-18 20-18h172q16 0 20 18l16 112q38 16 72 42l106-42q18-6 26 8l86 148q10 18-4 28l-90 70q2 14 2 42t-2 42z"></path>
                    </svg>
                </div>
            </div>
            <div className="chat-group-focused-messages">
                { list }
            </div>
            <div className="chat-group-focused-message-tools" style={{ height : singleLineMode ? "75px" : "unset" }}>
                <textarea className="chat-group-focused-textarea textarea"
                    onChange={handleInput}
                    value={input}
                    onKeyDown={sendOnEnter}
                    disabled={newChannelNotSaved}/>
                <div className="chat-group-focused-container7">
                    <div className="chat-group-focused-text-tools">
                        <svg viewBox="0 0 1024.5851428571427 1024"
                            className="chat-group-focused-more-text"
                            onClick={() => !newChannelNotSaved && setSingleLineMode(!singleLineMode)}>
                            <path d="M507.429 676.571l66.286-66.286-86.857-86.857-66.286 66.286v32h54.857v54.857h32zM758.857 265.143c-5.143-5.143-13.714-4.571-18.857 0.571l-200 200c-5.143 5.143-5.714 13.714-0.571 18.857s13.714 4.571 18.857-0.571l200-200c5.143-5.143 5.714-13.714 0.571-18.857zM804.571 604.571v108.571c0 90.857-73.714 164.571-164.571 164.571h-475.429c-90.857 0-164.571-73.714-164.571-164.571v-475.429c0-90.857 73.714-164.571 164.571-164.571h475.429c22.857 0 45.714 4.571 66.857 14.286 5.143 2.286 9.143 7.429 10.286 13.143 1.143 6.286-0.571 12-5.143 16.571l-28 28c-5.143 5.143-12 6.857-18.286 4.571-8.571-2.286-17.143-3.429-25.714-3.429h-475.429c-50.286 0-91.429 41.143-91.429 91.429v475.429c0 50.286 41.143 91.429 91.429 91.429h475.429c50.286 0 91.429-41.143 91.429-91.429v-72c0-4.571 1.714-9.143 5.143-12.571l36.571-36.571c5.714-5.714 13.143-6.857 20-4s11.429 9.143 11.429 16.571zM749.714 182.857l164.571 164.571-384 384h-164.571v-164.571zM1003.429 258.286l-52.571 52.571-164.571-164.571 52.571-52.571c21.143-21.143 56.571-21.143 77.714 0l86.857 86.857c21.143 21.143 21.143 56.571 0 77.714z"></path>
                        </svg>
                        <svg viewBox="0 0 1024 1024"
                            className="chat-group-focused-attach"
                            >
                            <path d="M884.608 441.301l-392.107 392.107c-41.685 41.685-96.256 62.507-150.955 62.507s-109.269-20.821-150.955-62.507-62.507-96.256-62.507-150.955 20.821-109.269 62.507-150.955l392.107-392.107c25.003-25.003 57.728-37.504 90.581-37.504s65.536 12.501 90.581 37.504 37.504 57.728 37.504 90.581-12.501 65.536-37.504 90.581l-392.533 392.107c-8.363 8.363-19.243 12.544-30.208 12.544s-21.845-4.181-30.208-12.501-12.501-19.2-12.501-30.208 4.181-21.845 12.501-30.208l362.24-361.813c16.683-16.64 16.683-43.648 0.043-60.331s-43.648-16.683-60.331-0.043l-362.24 361.813c-25.003 25.003-37.504 57.856-37.504 90.539s12.501 65.536 37.504 90.539 57.856 37.504 90.539 37.504 65.536-12.501 90.539-37.504l392.533-392.107c41.685-41.685 62.507-96.341 62.507-150.912s-20.864-109.269-62.507-150.912-96.341-62.507-150.912-62.507-109.269 20.864-150.912 62.507l-392.107 392.107c-58.325 58.325-87.509 134.869-87.509 211.285s29.184 152.96 87.509 211.285 134.869 87.509 211.285 87.509 152.96-29.184 211.285-87.509l392.107-392.107c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                        </svg>
                        <svg viewBox="0 0 950.8571428571428 1024"
                            className="chat-group-focused-itinerary">
                            <path d="M73.143 950.857h164.571v-164.571h-164.571v164.571zM274.286 950.857h182.857v-164.571h-182.857v164.571zM73.143 749.714h164.571v-182.857h-164.571v182.857zM274.286 749.714h182.857v-182.857h-182.857v182.857zM73.143 530.286h164.571v-164.571h-164.571v164.571zM493.714 950.857h182.857v-164.571h-182.857v164.571zM274.286 530.286h182.857v-164.571h-182.857v164.571zM713.143 950.857h164.571v-164.571h-164.571v164.571zM493.714 749.714h182.857v-182.857h-182.857v182.857zM292.571 256v-164.571c0-9.714-8.571-18.286-18.286-18.286h-36.571c-9.714 0-18.286 8.571-18.286 18.286v164.571c0 9.714 8.571 18.286 18.286 18.286h36.571c9.714 0 18.286-8.571 18.286-18.286zM713.143 749.714h164.571v-182.857h-164.571v182.857zM493.714 530.286h182.857v-164.571h-182.857v164.571zM713.143 530.286h164.571v-164.571h-164.571v164.571zM731.429 256v-164.571c0-9.714-8.571-18.286-18.286-18.286h-36.571c-9.714 0-18.286 8.571-18.286 18.286v164.571c0 9.714 8.571 18.286 18.286 18.286h36.571c9.714 0 18.286-8.571 18.286-18.286zM950.857 219.429v731.429c0 40-33.143 73.143-73.143 73.143h-804.571c-40 0-73.143-33.143-73.143-73.143v-731.429c0-40 33.143-73.143 73.143-73.143h73.143v-54.857c0-50.286 41.143-91.429 91.429-91.429h36.571c50.286 0 91.429 41.143 91.429 91.429v54.857h219.429v-54.857c0-50.286 41.143-91.429 91.429-91.429h36.571c50.286 0 91.429 41.143 91.429 91.429v54.857h73.143c40 0 73.143 33.143 73.143 73.143z"></path>
                        </svg>
                        <svg viewBox="0 0 1024 1024"
                            className="chat-group-focused-picture">
                            <path d="M86 256h84v598h598v84h-598q-34 0-59-25t-25-59v-598zM470 512l-128 170h512l-172-212-126 158zM938 682q0 34-25 60t-59 26h-512q-34 0-60-26t-26-60v-512q0-34 26-59t60-25h512q34 0 59 25t25 59v512z"></path>
                        </svg>
                        <svg viewBox="0 0 804.5714285714286 1024"
                            className="chat-group-focused-more">
                            <path d="M219.429 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857zM512 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857zM804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                        </svg>
                    </div>
                    <div className="chat-group-focused-send-button-container" onClick={send}>
                        <svg viewBox="0 0 1024.5851428571427 1024"
                            className="chat-group-focused-send">
                            <path d="M1008 6.286c12 8.571 17.714 22.286 15.429 36.571l-146.286 877.714c-1.714 10.857-8.571 20-18.286 25.714-5.143 2.857-11.429 4.571-17.714 4.571-4.571 0-9.143-1.143-13.714-2.857l-301.143-122.857-170.286 186.857c-6.857 8-16.571 12-26.857 12-4.571 0-9.143-0.571-13.143-2.286-14.286-5.714-23.429-19.429-23.429-34.286v-258.286l-269.714-110.286c-13.143-5.143-21.714-17.143-22.857-31.429-1.143-13.714 6.286-26.857 18.286-33.714l950.857-548.571c12-7.429 27.429-6.857 38.857 1.143zM812.571 862.857l126.286-756-819.429 472.571 192 78.286 493.143-365.143-273.143 455.429z"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}

const Message = forwardRef(function Message(props, ref) {
    var chatStyle = props.chat.SentByAccountId === props.user.Id
        ? "sent"
        : "received";

    const format = new Intl.DateTimeFormat("en", {
        timeStyle: "short",
    });

    var formattedTime = format.format(new Date(props.chat.SentDateTime))

    const embeddedLink = props.chat.MessageBody?.match(/(?:\(\"(.+)\"\)\[\"((?:https|http)\:\/\/.*\..*)\"\])/) ?? [];

    const plainTextLink = props.chat.MessageBody?.match(/(?: \s |^)(?: https | http) \: \/\/.*\..*\s*/) ?? [];
    var linkPreview = null;
    if (embeddedLink.length > 0) {
        linkPreview = <LinkPreview url={embeddedLink[2]} />
    }

    const getFormattedMessage = (message, idx) => {
        if (!embeddedLink || embeddedLink.length == 0)
            return <span key={idx}> {message} </span>;

        if (!message.includes(embeddedLink[0]))
            return <span key={idx}> {message} </span>;

        return (
            <span key={idx}>
                {message.substring(0, embeddedLink.index)}
                <a href={embeddedLink[2]}>
                    {embeddedLink[1]}
                </a>
                {message.substring(embeddedLink.index + embeddedLink[0].length)}
            </span>)
    }

    return (
        <div className={"chat-group-focused-message-" + chatStyle} ref={ref}>
            <div className="chat-group-focused-container5">
                {
                    props.chat.SentByAccount.Id !== props.user.Id &&
                    <span className="chat-group-focused-text5">{props.chat.SentByAccount.Name}</span>
                }
                <span className="chat-group-focused-text4">{formattedTime}</span>
            </div>
            {
                props.chat.MessageBody.split("\n").map((i, key) => {                        
                    return (getFormattedMessage(i, key));
                })
            }
            {
                linkPreview
            }
        </div>
    )
});

const LinkPreview = (props) => {
    const [previewData, setPreviewData] = useState();

    useEffect(() => {
        const load = async() => {
            var data = await getLinkPreview(props.url);
            console.log(data);
            setPreviewData(data);
        }

        if (!props.url)
            return;

        load();
    }, [props.url]);

    if (!previewData)
        return;

    var urlDescription = previewData.Description;
    if (previewData.Description?.length > 50)
        urlDescription = previewData.Description?.slice(0, 50) + "...";

    if (previewData.ImageUrl?.length === 0) {
        return;
    }

    return (
        <div>
            <a href={props.url}>
                <div className="link-preview">
                    <img src={previewData.ImageUrl}
                        className="link-preview-image" />
                    <h3 className="link-preview-title">{previewData.Title}</h3>
                    <span className="link-preview-description">{urlDescription}</span>
                </div>
            </a>
        </div>
    )
}

export default Chats



