import React, { useState, useEffect, forwardRef, useRef, useCallback, useMemo } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";
import { Calendar, Views, momentLocalizer, DateLocalizer } from 'react-big-calendar'
import moment from 'moment'

import Navbar from '../../components/NavBar/navbar.js';
import Footer from '../../components/Footer/footer.js';
import Sidebar from '../../components/Sidebar/sidebar.js';
import Popup from '../../components/Popup/popup.js';
import Loading from '../../components/Loading/loading.js';
import './style.css'
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { getAccount } from '../../data/account-data-access.js';
import { getItinerariesByAccountId, getItinerariesByGroupId, getItineraryById, createItinerary, updateItinerary, deleteItinerary } from '../../data/itinerary-data-access';
import { getGroups, createGroup, addGroupMember, removeGroupMember } from '../../data/group-data-access';

const Itineraries = (props) => {
    const { user, isAuthenticated, isAuthLoading } = useAuth0();
    const [searchParams, setSearchParams] = useSearchParams();
    const [itineraries, setItineraries] = useState(null);
    const [selectedItinerary, setSelectedItinerary] = useState();
    const [account, setAccount] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const load = async () => {
            var loadedAccount = await getUser();

            if (!loadedAccount)
                return;

            await loadItineraries(loadedAccount.Id);
        }

        load();
    }, [user]);

    useEffect(() => {
        if (itineraries)
            setIsLoading(false);

    }, [itineraries])

    useEffect(() => {
        if (!selectedItinerary)
            return;

        setSearchParams({
            itinerary: selectedItinerary.Id
        })
    }, [selectedItinerary])

    const loadItineraries = async (accountId) => {
        var res = await getItinerariesByAccountId(accountId);
        setItineraries(res);

        if (searchParams) {
            var itineraryIdFromUrl = searchParams.get("itinerary");
            var idx = res.findIndex((itinerary) => itinerary.Id == itineraryIdFromUrl);
            setSelectedItinerary(res[idx]);
        }
    }

    const getUser = async () => {
        if (!isAuthenticated || isAuthLoading)
            return;

        var res = await getAccount(user.email);

        setAccount(res);

        return res;
    }

    const itineraryBody = () => {
        if (isLoading) {
            return (
                <Loading />
            )
        }
        else {
            return (
                <div className="itineraries-container3">
                    <ItineraryList itineraries={itineraries}
                        setSelectedItinerary={setSelectedItinerary}
                        selectedItinerary={selectedItinerary}
                        account={account}
                        loadItineraries={loadItineraries} />
                    <ItineraryCalendar itinerary={selectedItinerary} />
                </div>
            )
        }
    }

    return (
        <div className="itineraries-container">
            <div className="itineraries-container1">
                <Navbar />
                <div className="itineraries-container2">
                    <Sidebar itinerariesSelected={true} />
                    {
                        itineraryBody()
                    }
                </div>
                <Footer />
            </div>
        </div>
    )
}

const ItineraryList = (props) => {
    const [itineraries, setItineraries] = useState(props.itineraries);
    const [addNewItineraryOpen, setAddNewItineraryOpen] = useState(false);

    useEffect(() => {
        setItineraries(props.itineraries);
    }, [props.itineraries]);

    useEffect(() => {
        if (props.selectedItinerary || !props.itineraries || props.itineraries.length === 0)
            return;

        props.setSelectedItinerary(props.itineraries[0])
    })

    var hasItineraries = itineraries && itineraries.length > 0;

    return (
        <div className="itineraries-list-container">
            <Popup trigger={addNewItineraryOpen}
                   setTrigger={setAddNewItineraryOpen}
                   className="itineraries-list-add"
                   contentClassName="add-new-itinerary-popup-content"
                showCloseButton={true}>
                <CreateItineraryPopupContent accountId={props.account?.Id} loadItineraries={props.loadItineraries} />
            </Popup>
            <div className="itineraries-list-header">
                <h1 className="itineraries-list-header-text">Itineraries</h1>
                <div className="itineraries-list-header-controls">
                    <svg viewBox="0 0 1024 1024"
                        className="itinerary-list-controls-filter-icon">
                        <path d="M256 554v-84h512v84h-512zM128 256h768v86h-768v-86zM426 768v-86h172v86h-172z"></path>
                    </svg>
                    <div className="add-new-itinerary-button-container">
                        <svg viewBox="0 0 1024 1024"
                            className="itinerary-list-controls-add-icon"
                            onClick={() => setAddNewItineraryOpen(true)}>
                            <path d="M726 470v-86h-172v-170h-84v170h-172v86h172v170h84v-170h172zM938 170v768l-170-170h-598q-34 0-59-26t-25-60v-512q0-34 25-59t59-25h684q34 0 59 25t25 59z"></path>
                        </svg>
                    </div>

                </div>
            </div>
            <div className="itineraries-list-body">
                {
                    !hasItineraries &&
                    <span className="no-content-message">There are no itineraries to show</span>
                }
                {
                    hasItineraries &&
                    itineraries.map((c, idx) => <ItineraryListItem key={idx}
                        itinerary={c}
                        selectedItinerary={props.selectedItinerary}
                        setSelectedItinerary={(itinerary) => props.setSelectedItinerary(itinerary)} />)
                }
            </div>
        </div>
    )
}

const ItineraryListItem = (props) => {
    const isSelected = props.selectedItinerary?.Id === props.itinerary.Id;

    var itineraryName = props.itinerary?.Name ?? "New Itinerary";

    if (itineraryName > 50)
        itineraryName = itineraryName.slice(0, 50) + "...";

    var groupName = props.itinerary?.Group?.GroupName ?? "";

    if (groupName > 50)
        groupName = groupName.slice(0, 50) + "...";

    return (
        <div className="itinerary-list-item-container"
            style={{ backgroundColor: isSelected ? "var(--dl-color-gray-700)" : "unset" }}
            onClick={() => props.setSelectedItinerary(props.itinerary)}>
            <div className="itinerary-list-item-container1">
                <div className="itinerary-list-item-container2">
                    <div>
                        <h1 className="itinerary-list-item-name">
                            {itineraryName}
                        </h1>
                        <h3 className="itinerary-list-item-group-name">
                            {groupName}
                        </h3>
                    </div>
                   
                    <svg viewBox="0 0 1024 1024"
                        className="itinerar-list-item-arrow-icon">
                        <path d="M250 176l92-90 426 426-426 426-92-90 338-336z"></path>
                    </svg>
                </div>

            </div>
        </div>
    )
}

const ItineraryCalendar = (props) => {
    const localizer = momentLocalizer(moment)
    const [itinerary, setItinerary] = useState();
    const [addNewItineraryItemOpen, setAddNewItineraryItemOpen] = useState(false);
    const [selectedStartEndDateRange, setSelectedStartEndDateRange] = useState();

    const { defaultDate, scrollToTime } = useMemo(
        () => ({
            defaultDate: itinerary?.StartDate ?? new Date(),
            scrollToTime: new Date(1970, 1, 1, 6),
        }),
        []
    )

    useEffect(() => {
        loadItinerary();
    }, [props.itinerary]);

    useEffect(() => {
        if (!addNewItineraryItemOpen)
            setSelectedStartEndDateRange();

    }, [addNewItineraryItemOpen])

    const loadItinerary = async () => {
        if (props.itinerary?.Id === null)
            return;

        var res = await getItineraryById(props.itinerary?.Id)

        if (!res)
            return;

        setItinerary(res);
    }

    const handleSelectSlot = useCallback(
        ({ start, end }) => {
            setSelectedStartEndDateRange({
                StartDate: start,
                EndDate: end
            })
            setAddNewItineraryItemOpen(true);
        },
        []
    )

    const handleSelectEvent = useCallback(
        (event) => window.alert(event.title),
        []
    )

    var events = [];

    if (itinerary?.Items && itinerary?.Items.length > 0) {
        events = itinerary.Items.map((item, index) => ({
            id: item.Id,
            title: item.Name,
            start: new Date(item.StartDate),
            end: new Date(item.EndDate)
        }));
    }

    return (
        <div className="itinerary-calendar-container">
            <Calendar
                defaultDate={defaultDate}
                defaultView={Views.WEEK}
                localizer={localizer}
                events={events}
                style={{ height: 700 }}
                selectable
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                dayLayoutAlgorithm="no-overlap"
                defaultView={Views.WEEK}
            />

            <Popup trigger={addNewItineraryItemOpen}
                setTrigger={setAddNewItineraryItemOpen}
                className="itineraries-list-add"
                contentClassName="add-new-itinerary-popup-content"
                showCloseButton={true}>
                <AddItineraryItemPopupContent itinerary={itinerary}
                    setPopupOpen={setAddNewItineraryItemOpen}
                    startEndDateRange={selectedStartEndDateRange}
                    reloadItinerary={loadItinerary}/>
            </Popup>
        </div>
    )
}

const CreateItineraryPopupContent = (props) => {
    const [itineraryName, setItineraryName] = useState("");
    const [selectedGroupId, setSelectedGroupId] = useState(-1);
    const [userGroups, setUserGroups] = useState([]);
    const [newGroupMembers, setNewGroupMembers] = useState([]);
    const [newGroupMember, setNewGroupMember] = useState("");

    useEffect(() => {
        const loadUserGroups = async () => {
            var res = await getGroups(props.accountId);

            setUserGroups(res);
        }
        loadUserGroups();
    }, [props.accountId]);

    const handleInput = e => {
        if (e.target.value === '\n') {
            return;
        }

        setNewGroupMember(e.target.value);
    }

    const addOnEnter = e => {
        if (e.key === 'Enter') {
            setNewGroupMembers([...newGroupMembers, newGroupMember])
            setNewGroupMember("");
        }
    }

    const create = async () => {
        //need to show a "itinerary name must be set or something stupid"
        if (itineraryName?.trim().length === 0)
            return;

        var groupId = selectedGroupId == -1
                    ? null
                    : selectedGroupId;

        var request = {
            GroupId: groupId,
            CreatedByAccountId: props.accountId,
            Name: itineraryName,
            NewGroupMembers: newGroupMembers
        }

        var result = await createItinerary(request);

        //need to show something to the user here
        if (!result)
            return;

        await props.loadItineraries(props.accountId);
    }

    var userGroupOptions = "";
    if (userGroups.length > 0) {
        userGroupOptions = userGroups.map((userGroup, index) =>
            <option value={`${userGroup.Id}`}>{userGroup.GroupName}</option>
        );
    }

    var newGroupMembersList = "";
    if (newGroupMembers.length > 0) {
        newGroupMembersList = newGroupMembers.map((newGroupMember, index) =>
            <NewGroupMemberName name={newGroupMember} key={index }/>
        );
    }

    return (
        <div className="create-itinerary-popup">
            <p>Create new Itinerary</p>

            <input
                type="text"
                placeholder="Name..."
                className="create-itinerary-popup-input"
                onChange={(event) => {
                    setItineraryName(event.target.value)
                }}
                value={itineraryName}
            />

            <label>
                Group:
            </label>

            <select className="create-itinerary-popup-group-select"
                onChange={(event) => {
                    setSelectedGroupId(parseInt(event.target.value))
                }}
                defaultValue="Create new group">
                <option value="-1">Create new group</option>
                {
                    userGroupOptions
                }
            </select>

            {selectedGroupId == -1 &&
                <div className="create-itinerary-popup-new-group-container">
                    <label>
                        Invite your friends:
                    </label>

                    <input type="text"
                        placeholder="Email or name..."
                        className="create-itinerary-popup-input"
                        value={newGroupMember}
                        onChange={handleInput}
                        onKeyDown={addOnEnter}
                    />

                    <div className="create-itinerary-popup-new-group-members-container">
                        {
                            newGroupMembersList
                        }
                    </div>
                </div>
            }
            <button className="create-itinerary-popup-button button"
                onClick={() => create()}>
                    <span>Create</span>
            </button>
        </div>
    )
}

const NewGroupMemberName = (props) => {
    return (
        <div className="new-group-member-name-container">
            <p className="new-group-member-name">{props.name}</p>
            <svg viewBox="0 0 1024 1024" className="new-group-member-name-delete-icon">
                <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM353.835 414.165l97.835 97.835-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l97.835-97.835 97.835 97.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-97.835-97.835 97.835-97.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-97.835 97.835-97.835-97.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
            </svg>
        </div>
    )
}

const AddItineraryItemPopupContent = (props) => {
    const [itineraryItemName, setItineraryItemName] = useState("");
    const [startDate, setStartDate] = useState(moment(props.startEndDateRange?.StartDate ?? new Date()).format("YYYY-MM-DDTHH:mm"));
    const [endDate, setEndDate] = useState(moment(props.startEndDateRange?.EndDate ?? new Date()).format("YYYY-MM-DDTHH:mm"));

    const create = async () => {
        //need to show a "itinerary name must be set or something stupid"
        if (itineraryItemName?.trim().length === 0)
            return;
       
        var request = {
            ...props.itinerary,
            Items: [
                {
                    Name: itineraryItemName,
                    StartDate: startDate,
                    EndDate: endDate,
                    Attributes: []
                }
            ]
        }

        var result = await updateItinerary(request);

        //need to show something to the user here
        if (!result)
            return;

        props.setPopupOpen(false);
        await props.reloadItinerary();
    }

    const giveItemNameFocus = () => {
        var textArea = document.getElementById("event-name-text-input");
        textArea?.focus()
        textArea?.setSelectionRange(textArea.value.length, textArea.value.length);
    }

    return (
        <div className="create-itinerary-popup">
            <p>Add new event</p>

            <input
                type="text"
                placeholder="Event Name..."
                className="add-itinerary-item-popup-input"
                onChange={(event) => {
                    setItineraryItemName(event.target.value)
                }}
                value={itineraryItemName}
                id="event-name-text-input"
            />


            <label className="add-itinerary-item-popup-label">
                Start Date:
            </label>

            <input
                type="datetime-local"
                className="add-itinerary-item-popup-input"
                onChange={(event) => {
                    setStartDate(event.target.value)
                }}
                value={startDate}
            />


            <label className="add-itinerary-item-popup-label">
                End Date:
            </label>

            <input
                type="datetime-local"
                className="add-itinerary-item-popup-input"
                onChange={(event) => {
                    setEndDate(event.target.value)
                }}
                value={endDate}
            />

            <button className="create-itinerary-popup-button button"
                onClick={() => create()}>
                <span>Create</span>
            </button>
        </div>
    )
}

export default Itineraries