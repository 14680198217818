import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './style.css'

const Waitlist = (props) => {
    const [email, setEmailState] = useState('');
    const [addedToWaitlist, setAddedToWaitlist] = useState(false);
    const [errors, setErrors] = useState('');

    let waitistConfirmationStyle = {
        display: addedToWaitlist ? "flex" : "none"
    }

    let submitButtonStyle = {
        display: !addedToWaitlist ? "flex" : "none"
    }

    //this url should be in a config
    const addToWaitlist = (firstLastName, email) => {

        if (!email) {
            setErrors("Email cannot be empty");
            return;
        }

        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            setErrors("Email is not valid");
            return;
        }
        
        fetch("https://docs.google.com/forms/d/e/1FAIpQLSfeb3GARK1YXPvzA68RPHeaOStJWU47ESWhN9C4FFEGvKFpYA/formResponse?"
            + new URLSearchParams({
                "entry.2012413200": "no response",
                "entry.1287158286": email
            }),
            {
                mode: "no-cors"
            });

        setErrors("");
        setAddedToWaitlist(true);
    }

    return (
        <div className="waitlist-container1">
            <span className="waitlist-text">{props.text1}</span>
            <h1 className="waitlist-text1">{props.heading}</h1>
            <span className="waitlist-text2">{props.text2}</span>
            <div className="waitlist-container2">
                <div className="email-submission-container">
                    <input
                        type="text"
                        placeholder={props.textinput_placeholder1}
                        className="waitlist-email input"
                        onChange={(event) => {
                            setEmailState(event.target.value);
                        }}
                        value={email}
                    />
                </div>
                <button className="waitlist-button button"
                        onClick={() => addToWaitlist('NULL', email)}
                    style={submitButtonStyle}>{props.button1}</button>
                <div className="email-submission-success-container" style={waitistConfirmationStyle} >
                    <span className="email-submission-success">Subscribed! </span>
                    <svg width="40px" height="40px" viewBox="0 0 24 24" className="check-icon" fill="white">
                        <path d="M9.02975 3.3437C10.9834 2.88543 13.0166 2.88543 14.9703 3.3437C17.7916 4.00549 19.9945 6.20842 20.6563 9.02975C21.1146 10.9834 21.1146 13.0166 20.6563 14.9703C19.9945 17.7916 17.7916 19.9945 14.9703 20.6563C13.0166 21.1146 10.9834 21.1146 9.02975 20.6563C6.20842 19.9945 4.0055 17.7916 3.3437 14.9703C2.88543 13.0166 2.88543 10.9834 3.3437 9.02974C4.0055 6.20841 6.20842 4.00549 9.02975 3.3437ZM15.0524 10.4773C15.2689 10.2454 15.2563 9.88195 15.0244 9.6655C14.7925 9.44906 14.4291 9.46159 14.2126 9.6935L11.2678 12.8487L9.77358 11.3545C9.54927 11.1302 9.1856 11.1302 8.9613 11.3545C8.73699 11.5788 8.73699 11.9425 8.9613 12.1668L10.8759 14.0814C10.986 14.1915 11.1362 14.2522 11.2919 14.2495C11.4477 14.2468 11.5956 14.181 11.7019 14.0671L15.0524 10.4773Z" />
                    </svg>
                </div>
            </div>
            <span className="email-submission-errors">{errors}</span>
        </div>
    )
}

Waitlist.defaultProps = {
    text: 'Join our waitlist! Stay up to date on the latest news from Twiyo.',
    textinput_placeholder: 'email@email.com',
    button: 'Button',
    text1: 'Join our Waitlist',
    heading: 'Get Your Trip out of the Group Chat',
    text2: 'Subscribe to our waitlist for the latest updates and travel tips\n',
    textinput_placeholder1: 'Email here...',
    button1: 'Subscribe',
    rootClassName: '',
}

Waitlist.propTypes = {
    text: PropTypes.string,
    textinput_placeholder: PropTypes.string,
    button: PropTypes.string,
    text1: PropTypes.string,
    heading: PropTypes.string,
    text2: PropTypes.string,
    textinput_placeholder1: PropTypes.string,
    button1: PropTypes.string,
    rootClassName: PropTypes.string,
}

export default Waitlist
