
import React, { useState, Component } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import './style.css';

const AccountManagement = (props) => {
    const { user, isAuthenticated, isLoading } = useAuth0();

    return (
        <AccountManagementModel user={user} isAuthenticated={isAuthenticated} isAuth0Loading={isLoading} />
    );
}

export class AccountManagementModel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            isAuthenticated: props.isAuthenticated,
            isAuth0Loading: props.isAuth0Loading,
            loading: true
        };
        
    }

    componentDidMount() {
        this.get();
    }

    async get() {
        if (!this.state.isAuthenticated || this.state.isLoading) {
            return;
        }

        axios.get("api/account?", {
                params: {
                    email: this.state.user.email
                }
            })
            .then(res => {
                console.log(res)

                this.setState = {
                    ...this.state,
                    user: {
                        ...this.state.user,
                        id: res.data.Id,
                        firstName: res.data.FirstName,
                        lastName: res.data.LastName,
                        phone: res.data.Phone,
                        createdDate: res.data.CreatedDate,
                        modifiedDate: res.data.ModifiedDate
                    },
                    loading: false
                };
             })
    }

    async update() {
        axios.put('api/account',
            {
                FirstName: this.state.user.firstName,
                LastName: this.state.user.lastName,
                Phone: this.state.user.phone,
                Email: this.state.user.email
            });
    }

    render() {

        return (
            <div className="account-management-container">
                <div className="account-management-header">
                    <img src={this.state.user.picture}
                        alt={this.state.user.name}
                        className="account-management-profile-image" />
                    <p className="account-management-name-header">{this.state.user.name}</p>
                </div>
                <h3 className="account-management-section-title">About You</h3>
                <AccountManagementProperty label="First Name"
                    property={this.state.user.firstName}
                    update={() => this.update()} />
                <AccountManagementProperty label="Last Name"
                    property={this.state.user.lastName}
                    update={() => this.update()} />

                <h3 className="account-management-section-title">Contact</h3>
                <AccountManagementProperty label="Phone"
                    property={this.state.user.phone}
                    update={() => this.update()} />
                <AccountManagementProperty label="Email"
                    property={this.state.user.email}
                    update={() => this.update()} />
            </div>
        )
    }
}

const AccountManagementProperty = (props) => {
    const [property, setProperty] = useState(props.property);
    const [controlsHidden, setControlsHidden] = useState(true);

    const controlsStyle = {
        display: controlsHidden ? "none" : "flex"
    }

    const makeControlsVisible = () => {
        setControlsHidden(false)
    }

    const makeControlsHidden = () => {
        setControlsHidden(true);
        setProperty(props.property)
    }

    const updateProperty = () => {
        if (props.property === property)
            return;

        props.update();
        makeControlsHidden();
    }

    return (
        <div className="account-management-property-container">
            <p className="account-management-property-label">{props.label}</p>
            <div className="account-management-property-input-container">
                <input
                    type="text"
                    placeholder={props.label}
                    className="account-management-property-input"
                    onChange={(event) => {
                        setProperty(event.target.value)
                    }}
                    onFocus={(event) => {
                        makeControlsVisible()
                    }}
                    onBlur={(event) => {
                        makeControlsHidden()
                    }}
                    value={property}
                />
                <div className="account-management-property-controls" style={controlsStyle}>
                    <button className="account-management-property-controls-button"
                        onMouseDown={updateProperty}>
                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" className="account-management-property-controls-icon">
                            <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                    <button className="account-management-property-controls-button"
                        onMouseDown={makeControlsHidden}>
                        <svg viewBox="0 0 1024 1024" className="account-management-property-controls-icon">
                            <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                         </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AccountManagement;
