import React from 'react'

import Navbar from '../../components/NavBar/navbar.js'
import MemberCard from '../../components/MemberCard/member-card.js'
import Footer from '../../components/Footer/footer.js'
import './style.css'

//import reaganProfilePic from '../../images/ProfilePics/reagan.jpeg'
//import julesProfilePic from '../../images/ProfilePics/jules.jpeg'
//import christinaProfilePic from '../../images/ProfilePics/christina.jpeg'
//import calebProfilePic from '../../images/ProfilePics/caleb.jpg'

const Team = (props) => {
    const reaganProfilePic = ''
    const julesProfilePic = ''
    const christinaProfilePic = ''
    const calebProfilePic = ''


    return (
        <div className="team-container">
            <Navbar></Navbar>
            <div className="team-blog">
                <MemberCard
                    image_src={reaganProfilePic}
                    rootClassName="rootClassName3"
                    name="Reagan Wiles"
                    title="Co-Founder, CEO"
                    description="Coming from a long line of entrepreneurs residing in the Missouri Ozarks, Reagan has seen first hand what a good idea and a lot of hard work can create when starting a viable business. Though she is currently finishing up her final year as a journalism major at the University of Missouri, she feels a calling to the life of entrepreneurship and has been learning the ropes of being a business owner. Her multifaceted role within the company extends from orchestrating financing strategies and pitching to possible investors, to collaboratively crafting audience strategies and overseeing customer acquisition. Reagan co-leads and oversees day-to-day operations, steering the course of Twiyo's business creation. Beyond her entrepreneurial pursuits, she finds solace in hiking and backpacking, showcasing a love for adventure that mirrors the spirit of Twiyo.">
                </MemberCard>
                <MemberCard
                    image_src={julesProfilePic}
                    rootClassName="rootClassName5"
                    name="Jules Maslak"
                    title="Co-Founder, CTO"
                    description="Jules has been invested in Twiyo since its inception. Jules is a recent MU graduate and a knowledgeable professional with 3+ years of experience in e-commerce applications. Jules is devoted to leveraging his education and experience to provide Twiyo with the business solutions that allow the company to innovate the travel industry.">
                </MemberCard>
                <MemberCard
                    image_src={christinaProfilePic}
                    rootClassName="rootClassName5"
                    name="Christina Chao"
                    title="Brand Manager"
                    description="As the Brand Manager for Twiyo, Christina is the driving force behind the visual and narrative elements that define Twiyo’s identity. With a keen eye for design and a strategic mind, Christina brings a unique blend of creativity to the table. Through her professional background in social media marketing, she has a passion for building impactful brand identities and a compelling narrative that resonates with audiences. Outside of her career, she enjoys playing the piano, going to concerts with friends, and playing with her two Pomeranians.">
                </MemberCard>
                <MemberCard
                    image_src={calebProfilePic}
                    rootClassName="rootClassName5"
                    name="Caleb Horsch"
                    title="Application Developer"
                    description="As a long-time supporter of Twiyo, Caleb has seen the company's development from its beginnings. Although graduating from Mizzou with a degree in biochemistry, he is very tech-driven and has a deep intrigue for software development. Caleb is dedicated to retaining his involvement with programming through assisting with Twiyo's solutions alongside Jules. In his free time, he can be found playing sports and running or spending time with him and his brother's Doodles."
                >
                </MemberCard>
            </div>
            <Footer rootClassName="footer-root-class-name2"></Footer>
        </div>
    )
}

export default Team
