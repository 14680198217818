import React, { useState } from 'react'
import Navbar from '../../components/NavBar/navbar.js';
import LoginComponent from '../../components/Login/login.js';
import Footer from '../../components/Footer/footer.js';
import './style.css'

const LoginHelp = (props) => {
    const [emailErrors, setEmailErrors] = useState('');
    const [email, setEmail] = useState('');

    const [emailSent, setEmailSent] = useState(false);

    let emailConfirmationStyle = {
        display: emailSent ? "flex" : "none"
    }

    let submitButtonStyle = {
        display: !emailSent ? "flex" : "none"
    }

    const sendResetPasswordRequest = () => {
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email ?? ''))) {
            setEmailErrors("Email is not valid");
            return;
        }
        setEmailErrors("");
        setEmailSent(true);
    }

    return (
        <div className="loginScreen-container">
            <div className="loginScreen-container1">
                <Navbar/>
                <div className="login-container1">
                    <span className="login-text">Reset Password</span>
                    <div className="login-container2" >
                        <div className="email-submission-container">
                            <input
                                type="text"
                                placeholder="Email"
                                className="input"
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }}
                                value={email}
                            />
                            <span className="email-submission-errors">{emailErrors}</span>
                        </div>
                        <button className="login-button button"
                            onClick={() => sendResetPasswordRequest()}
                            style={submitButtonStyle}>
                            Reset Password
                        </button>
                        <div className="email-submission-success-container" style={emailConfirmationStyle} >
                            <span className="email-submission-success">Email Sent</span>
                            <svg width="40px" height="40px" viewBox="0 0 24 24" className="check-icon" fill="white">
                                <path d="M9.02975 3.3437C10.9834 2.88543 13.0166 2.88543 14.9703 3.3437C17.7916 4.00549 19.9945 6.20842 20.6563 9.02975C21.1146 10.9834 21.1146 13.0166 20.6563 14.9703C19.9945 17.7916 17.7916 19.9945 14.9703 20.6563C13.0166 21.1146 10.9834 21.1146 9.02975 20.6563C6.20842 19.9945 4.0055 17.7916 3.3437 14.9703C2.88543 13.0166 2.88543 10.9834 3.3437 9.02974C4.0055 6.20841 6.20842 4.00549 9.02975 3.3437ZM15.0524 10.4773C15.2689 10.2454 15.2563 9.88195 15.0244 9.6655C14.7925 9.44906 14.4291 9.46159 14.2126 9.6935L11.2678 12.8487L9.77358 11.3545C9.54927 11.1302 9.1856 11.1302 8.9613 11.3545C8.73699 11.5788 8.73699 11.9425 8.9613 12.1668L10.8759 14.0814C10.986 14.1915 11.1362 14.2522 11.2919 14.2495C11.4477 14.2468 11.5956 14.181 11.7019 14.0671L15.0524 10.4773Z" />
                            </svg>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    )
}

export default LoginHelp
