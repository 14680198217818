import React from 'react'
import { NavLink } from "react-router-dom";

import './style.css'

const NavigationLinks = (props) => {
    return (
        <nav className={`navigation-links-nav-${props.rootClassName} `}>
            <NavLink className="navigation-links-text" to="/Chat" >
                Travel
            </NavLink>
             <NavLink className="navigation-links-text1" to="/about" >
                Our Story
            </NavLink>
            <NavLink className="navigation-links-text1" to="/team">
                Team
            </NavLink>
            <NavLink className="navigation-links-text2" to="/blog">
                Blog
            </NavLink>
        </nav>
    )
}

export default NavigationLinks
