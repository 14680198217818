import About from "./screens/About/about";
import Blog from "./screens/Blog/blog";
import Home from "./screens/Home/home";
import Team from "./screens/Team/team";
import Waitlist from "./screens/Waitlist/waitlist";
import Login from "./screens/Login/login";
import LoginHelp from "./screens/Login/login-help";
import Register from "./screens/Login/register";
import Legal from "./screens/Legal/legal";
import Contact from "./screens/Contact/contact";
import Chats from "./screens/Chats/chats";
import Itineraries from "./screens/Itinerary/itineraries";
import Votes from "./screens/Votes/votes";
import AccountManagement from "./screens/Account/account-management";
import Profile from "./screens/Profile/profile";
import NotFound from "./screens/NotFound/not-found";
import ComponentTest from "./screens/ComponentTest";

const AppRoutes = [
    {
        index: true,
        path: "/",
        element: <Home />
    },
    {
        index: false,
        path: "/About",
        element: <About />
    },
    {
        index: false,
        path: "/Blog",
        element: <Blog />
    },
    {
        index: false,
        path: "/Team",
        element: <Team />
    },
    {
        index: false,
        path: "/Waitlist",
        element: <Waitlist />
    },
    {
        index: false,
        path: "/Login",
        element: <Login />
    },
    {
        index: false,
        path: "/Login-Help",
        element: <LoginHelp />
    },
    {
        index: false,
        path: "/Register",
        element: <Register />
    },
    {
        index: false,
        path: "/Legal",
        element: <Legal/>
    },
    {
        index: false,
        path: "/Contact",
        element: <Contact />
    },
    {
        index: false,
        path: "/Chat",
        element: <Chats />,
        isProtected: true
    },
    {
        index: false,
        path: "/Itineraries",
        element: <Itineraries />,
        isProtected: true
    },
    {
        index: false,
        path: "/Votes",
        element: <Votes />,
        isProtected: true
    },
    {
        index: false,
        path: "/Account",
        element: <AccountManagement />,
        isProtected: true
    },
    {
        index: false,
        path: "/Profile",
        element: <Profile />,
        isProtected: true
    },
    {
        index: false,
        path: "/ComponentTest",
        element: <ComponentTest />,
        isProtected: true
    },
    {
        index: false,
        path: "/*",
        element: <NotFound />
    }
];

export default AppRoutes;
