import React from 'react';
import Navbar from '../../components/NavBar/navbar.js';
import RegisterComponent from '../../components/Login/register.js';
import Footer from '../../components/Footer/footer.js';
import './style.css'

const Register = (props) => {


    return (
        <div className="loginScreen-container">
            <div className="loginScreen-container1">
                <Navbar></Navbar>
                <RegisterComponent rootClassName="register-root-class-name"></RegisterComponent>
                <Footer rootClassName="footer-root-class-name1"></Footer>
            </div>
        </div>
    )
}

export default Register
