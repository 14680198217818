import axios from 'axios';

async function getChannels(accountId) {
    var res = await axios.get("api/channel/" + accountId);

    console.log(res)

    return res.data;
}


async function createChannel(accountId, groupId, channelName) {
    var res = await axios.post("api/channel/",
        {
            CreatedByAccountId: accountId,
            GroupId: groupId,
            ChannelName: channelName
        })
    console.log(res);

    return res.data;
}

async function updateChannelName(channelId, channelName) {
    console.log(channelName);
    const config = { headers: { 'Content-Type': 'application/json' } };

    var res = await axios.put("api/channel/" + channelId + "/name", channelName, config)
    console.log(res);

    return res.data;
}

async function addChannelMember(accountId, channelId) {
    var res = await axios.put("api/channel/" + channelId,
        {
            AccountId: accountId
        })
    console.log(res);

    return res.data;
}

async function removeChannelMember(accountId, channelId) {
    var res = await axios.delete("api/channel/" + channelId + "/member/" + accountId)
    console.log(res);

    return res.data;
}

export { getChannels, createChannel, updateChannelName, addChannelMember, removeChannelMember }