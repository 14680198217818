import React from 'react'
import { NavLink } from "react-router-dom";

import twiyoSvg from './twiyo_logo.svg';

import './style.css'

const Logo = (props) => {
    return (
        <div className={`logo-logo ${props.rootClassName} `}>
            <NavLink className="navigation-links-text" to="/" >
                <img className="logo-image" src={twiyoSvg} alt="Twiyo company logo" />
            </NavLink>
        </div>
    )
}

export default Logo
