import React from 'react'
import { useNavigate } from "react-router-dom";

import Navbar from '../../components/NavBar/navbar.js'
import Waitlist from '../../components/Waitlist/waitlist.js'
import Footer from '../../components/Footer/footer.js'
import './style.css'

const Home = (props) => {
  const navigate = useNavigate();

  return (
    <div className="home-container">
      <div className="home-container1">
        <Navbar></Navbar>
        <div className="home-hero">
          <div className="home-container2">
            <h1 className="home-text">Travel with us and explore the world</h1>
            <span className="home-text1">
              <span>
                Discover new destinations, create unforgettable memories, and
                travel on your terms with our flexible booking options and cost
                splitting.
              </span>
            </span>
            <div className="home-btn-group">
                <button className="home-button button" onClick={() => navigate('/Waitlist')}>Get Started</button>
                <button className="home-button1 button" onClick={() => navigate('/About')}>Learn More</button>
            </div>
          </div>
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1525498128493-380d1990a112?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDI0fHxtaW5pbWFsaXNtJTIwZ3JlZW58ZW58MHx8fHwxNjI1ODQxMDcw&amp;ixlib=rb-1.2.1&amp;h=1200"
            className="home-image"
          />
        </div>
        <Waitlist rootClassName="waitlist-root-class-name1"></Waitlist>
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Home
