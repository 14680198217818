import axios from 'axios';

async function getItinerariesByAccountId(accountId) {
    try {
        var res = await axios.get("api/itinerary/account/" + accountId);
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

async function getItinerariesByGroupId(groupId) {
    try {
        var res = await axios.get("api/itinerary/group/" + groupId);
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

async function getItineraryById(itineraryId) {
    try {
        var res = await axios.get("api/itinerary/" + itineraryId);
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

async function createItinerary(request) {
    try {
        var res = await axios.post("api/itinerary/", request)
        console.log(res);

        return true;
    }
    catch (exception) {
        console.log(exception);
        return false;
    }
}

async function updateItinerary(request) {
    try {
        var res = await axios.put("api/itinerary/", request)
        console.log(res);

        return res.status === 200;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

async function deleteItinerary(itineraryId, deletedBy) {
    try {
        var res = await axios.delete("api/itinerary?", {
            params: {
                itineraryId: itineraryId,
                deletedBy: deletedBy
            }
        });
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

export { getItinerariesByAccountId, getItinerariesByGroupId, getItineraryById, createItinerary, updateItinerary, deleteItinerary }