import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';

import Logo from '../Logo/logo'
import NavigationLinks from '../NavigationLinks/navigation-links';
import MobileNavMenu from './mobile-nav-menu';
import WaitlistPopup from '../Waitlist/waitlist-popup.js';
import AccountIcon from '../Account/account-icon.js';
import NotificationIconButton from '../Notifications/notification-icon-button.js';

import './style.css';

const Navbar = (props) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const navigate = useNavigate();

    //the position fixed blocks scrolling, if menu open unset is backwards but it works
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen)
    }

    return (
        <div className="navbar-container">
            <header data-role="Header" className="navbar-header">
                <MobileNavMenu toggleMenu={toggleMobileMenu} isOpen={mobileMenuOpen}></MobileNavMenu>
                <Logo></Logo>
                <div className="navbar-container1">
                    <div className="navbar-nav">
                        <NavigationLinks rootClassName="rootClassName12"></NavigationLinks>
                    </div>
                </div>
                <div className="navbar-btn-group">
                    <WaitlistPopup />
                    <NotificationIconButton/>
                    <AccountIcon/>
                </div>
                <div data-role="BurgerMenu" className="navbar-burger-menu" onClick={toggleMobileMenu}>
                    <svg viewBox="0 0 1024 1024" className="navbar-icon">
                        <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                    </svg>
                </div>
            </header>
        </div>
    )
}

Navbar.defaultProps = {
    button: 'Join Waitlist',
    image_src: 'https://presentation-website-assets.teleporthq.io/logos/logo.png',
    image_alt: 'image',
    image_src1: 'f2cb6dcb-bb23-4096-bff7-c1d4e1413e39',
    image_alt1: 'image',
    text: 'Twiyo',
}

Navbar.propTypes = {
    button: PropTypes.string,
    image_src: PropTypes.string,
    image_alt: PropTypes.string,
    image_src1: PropTypes.string,
    image_alt1: PropTypes.string,
    text: PropTypes.string,
}

export default Navbar
