import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './style.css';

import { getFriendship, approveFriendRequest, rejectFriendRequest } from '../../data/friend-data-access.js';
import { updateNotification } from '../../data/notification-data-access.js';
import { getAccount, searchAccount } from '../../data/account-data-access.js';

const friendRequestNotification = 0;
const addedToGroup = 2;
const addedToItinerary = 3;
const addedToChat = 4;
const eventAddedToItinerary = 5;
const alternativeEventSuggested = 6;
const votingEndsSoon = 7;
const itineraryStartingSoon = 8;

//Do we want to have a mark read button instead of a nav arrow or in addition to it?
const NotificationPopupItem = (props) => {
    const navigate = useNavigate();

    if (props.notification && props.notification.Type.Id === friendRequestNotification) {
        return (
            <FriendRequestNotification account={props.account}
                navigate={navigate}
                notification={props.notification}
                reloadNotifications={props.reloadNotifications} />
        )
    }

    return (
        <DefaultNotificationItem account={props.account}
            navigate={navigate}
            notification={props.notification}
            reloadNotifications={props.reloadNotifications} />
    )
}

//need to make sure that request/accept buttons do not show up if they are already friends
const FriendRequestNotification = (props) => {
    const [friendship, setFriendship] = useState();

    useEffect(() => {
        getFriendshipStatus(props.account?.Id, props.notification?.ResourceId);
    }, [props.account]);

    const getFriendshipStatus = async (accountId, friendId) => {
        if (!accountId || !friendId || accountId === friendId)
            return;

        var res = await getFriendship(accountId, friendId);

        setFriendship(res);
    }

    const approveFriend = async () => {
        await updateNotification({
            ...props.notification,
            Read: true
        })

        await approveFriendRequest(props.account.Id, props.notification?.ResourceId)

        await props.reloadNotifications();
    }

    const rejectFriend = async () => {
        await updateNotification({
            ...props.notification,
            Read: true
        })

        await rejectFriendRequest(props.account.Id, props.notification?.ResourceId)

        await props.reloadNotifications();
    }

    const onItemClick = async () => {
        var user = await getAccount(null, props.notification?.ResourceId)

        if (!user)
            return;

        props.navigate(`/profile?username=${user.UserName}`)
    }

    const friendshipButton = () => {
        console.log(friendship);

        if (friendship
        && friendship?.Status?.Id === 0
        && friendship?.RequestedBy !== props.account?.Id) {
        return (
            <div className="notification-popup-item-controls">
                <button className="popup-item-button"
                    onClick={approveFriend}>
                    Accept
                </button>
                <button className="popup-item-button"
                    onClick={rejectFriend}>
                    Decline
                </button>
            </div>

            )
        }

        return (
            <div className="notification-popup-item-controls">
                <button className="popup-item-button"
                    disabled={true}>
                    Accepted
                </button>
            </div>
        );
    }

    return (
        <div className="notification-popup-item"
             onClick={onItemClick}>
            <span className="notification-message">
                { props.notification?.Message }
            </span>
            {
                friendshipButton()
            }
        </div>
    )
}

const DefaultNotificationItem = (props) => {

    const onItemClick = async () => {
        var resource = null;

        //This should probably be in the DB, I don't want to have to update this list for every new notification type
        switch (props.notification?.Type?.Id) {
            case addedToGroup:
                resource = "WeDontHaveAGroupPageYet" //TODO do not leave this in here
                break;
            case itineraryStartingSoon:
            case eventAddedToItinerary:
            case addedToItinerary:
                resource = "/itineraries?itinerary="
                break;
            case addedToChat:
                resource = "/chat?channel="
                break;
            case votingEndsSoon:
            case alternativeEventSuggested:
                resource = "/votes?poll="
                break;
            default:
                resource = "/chat"
        }

        if (resource) {
            await updateNotification({
                ...props.notification,
                Read: true
            })

            props.navigate(`${resource}${props.notification?.ResourceId}`)
        }
    }

    return (
        <div className="notification-popup-item"
            onClick={onItemClick}>
            <span className="notification-message">
                {props.notification?.Message}
            </span>
            <div className="notification-popup-item-controls">
                <svg viewBox="0 0 1024 1024"
                    className="popup-item-arrow-icon">
                    <path d="M250 176l92-90 426 426-426 426-92-90 338-336z"></path>
                </svg>
            </div>
        </div>
    )
}

export default NotificationPopupItem;
