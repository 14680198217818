import React from "react";

const SWITCH_WIDTH_PX = 36;
const HANDLE_DIAMETER_PX = 15;
const SWITCH_OFFSET_PX = 3;

const Switch = (props) =>
{
    const containerCheckedColor = "blue"
    const containerUncheckedColor = "aliceblue"
    const handleCheckedColor = "white"
    const handleUncheckedColor = "blue"

    return (
        <div style={{
                width: SWITCH_WIDTH_PX,
                height: HANDLE_DIAMETER_PX + 2 * SWITCH_OFFSET_PX,
                borderRadius: HANDLE_DIAMETER_PX,
                border: "1px #ddd solid",
                position: "relative",
                transition: "1s",
                cursor: "pointer",
                background: props.value ? containerCheckedColor : containerUncheckedColor,
                marginRight: "5px",
                marginLeft: "5px"
             }}

             onClick={() => {
                props.onClick(!props.value);
             }}>
            <div style={{
                    background: props.value ? handleCheckedColor : handleUncheckedColor,
                    borderRadius: "100%",
                    height: HANDLE_DIAMETER_PX,
                    width: HANDLE_DIAMETER_PX,
                    position: "absolute",
                    top: 2,
                    left: props.value
                        ? SWITCH_WIDTH_PX - HANDLE_DIAMETER_PX - SWITCH_OFFSET_PX
                        : SWITCH_OFFSET_PX,
                    transition: "1s",
                 }}
            >
            </div>
            <input
                type="checkbox"
                value={props.value}
                onChange={(e) => {
                    props.onClick(props.value);
                }}
                style={{ display: "none" }}
            />
        </div>
    );
};
export default Switch;
