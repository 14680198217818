import React from 'react';
import './style.css';

function Popup(props) {

    const tryClose = (event) => {
        if (event.target.id == 'popup-container') {
            props.setTrigger(false);
        }
    }

    const className = props.className ?? "popup";
    const contentClassName = props.contentClassName ?? "popup-content";

    return (props.trigger) ? (
        <div className={className} id="popup-container" onClick={(event) => tryClose(event)}>
            <div className={contentClassName}>

                {(props.showCloseButton ?? true) && (
                    <div className="close-button">
                        <svg viewBox="0 0 1024 1024" className="close-button-icon" onClick={() => props.setTrigger(false)}>
                            <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                        </svg>
                    </div>
                )}

                {props.children}
            </div>
        </div>
    ) : "";
}

export default Popup;