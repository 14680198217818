import React from "react";
import Logout from "../Login/logout-button";
import { NavLink } from "react-router-dom";
import './style.css';

const AccountMenu = (props) => {
    return (
        <div className="account-menu-body">
            <div className="account-menu-user-card">
                <NavLink className="navigation-links-text" to="/account" >
                    <div className="account-management-nav">
                        <img src={props.user.picture}
                            alt={props.user.name}
                            className="menu-account-icon" />
                        <p>Your Profile</p>
                    </div>
                </NavLink>
               
                <div className="account-menu-name-email-container">
                    <p>{props.user.name}</p>
                    <p className="account-menu-email">{props.user.email}</p>
                </div>
            </div>

            <Logout/>
        </div>
    )
}

export default AccountMenu