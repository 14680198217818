import React from 'react'
import './style.css'

const MemberCard = (props) => {
    return (
        <div className={`member-card-blog-post-card ${props.rootClassName} `}>
            <img
                alt={props.image_alt}
                src={props.image_src}
                className="member-card-image"
            />
            <div className="member-card-container">
                <h1 className="member-card-text">{props.name}</h1>
                <h2 className="member-card-text">{props.title}</h2>
                <span className="member-card-text1">{props.description}</span>
            </div>
        </div>
    )
}

export default MemberCard
