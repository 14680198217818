import React, { useState } from 'react'
import { NavLink } from "react-router-dom";

import './style.css'


class NewProfile {
    email;
    firstName;
    lastName;
    password;
}

const Register = (props) => {
    const [continueWithEmail, setContinueWithEmail] = useState(false);
    const [newProfile, setNewProfile] = useState(new NewProfile);

    return (
        <div className="login-container">
            {
                !continueWithEmail &&
                <LoginOptions setContinueWithEmail={setContinueWithEmail} newProfile={newProfile}/>
            }
            {
                continueWithEmail &&
                <RegisterViaEmail setContinueWithEmail={setContinueWithEmail} newProfile={newProfile}/>
            }
        </div>
    )
}

const LoginOptions = (props) => {
    const [emailErrors, setEmailErrors] = useState('');
    const [email, setEmail] = useState(props.newProfile.email);

    const continueWithEmail = () => {
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email ?? ''))) {
            setEmailErrors("Email is not valid");
            return;
        }

        setEmailErrors("");
        props.newProfile.email = email;
        props.setContinueWithEmail(true);
    }

    return (
        <div className="login-container1">
            <span className="login-text">Register</span>

            <div className="login-container2" >
                <div className="email-submission-container">
                    <input
                        type="text"
                        placeholder="Email"
                        className="input"
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                        value={email}
                    />
                    <span className="email-submission-errors">{emailErrors}</span>
                </div>
                <button className="login-button button"
                    onClick={() => continueWithEmail()}>
                        Continue with email
                </button>
            </div>

            <div>
                <div className="separator"></div>
                <span>OR</span>
                <div className="separator"></div>
            </div>

            <SocialLoginOptions/>
                
            <span>
                Already a member? &#160;
                <NavLink className="link-text" to="/login" >
                    Log in
                </NavLink>
            </span>
        </div>
    )
}

const SocialLoginOptions = (props) => {
    return (
        <div className="login-options-wrapper">
            <div className="social-login-container">
               
            </div>
        </div>
    )
}

const RegisterViaEmail = (props) => {
    const [firstName, setFirstName] = useState(props.newProfile.firstName);
    const [lastName, setLastName] = useState(props.newProfile.lastName);
    const [username, setUsername] = useState(props.newProfile.username);
    const [password, setPassword] = useState(props.newProfile.password);
    const [firstNameErrors, setFirstNameErrors] = useState('');
    const [lastNameErrors, setLastNameErrors] = useState('');
    const [usernameErrors, setUsernameErrors] = useState('');
    const [passwordErrors, setPasswordErrors] = useState('');

    //this url should be in a config
    const register = () => {

        if (!firstName) {
            setFirstNameErrors("First name is required");
            return;
        }

        if (!lastName) {
            setLastNameErrors("Last name is required");
            return;
        }

        if (!username) {
            setUsernameErrors("Password is required");
            return;
        }

        if (!password) {
            setPasswordErrors("Password cannot be empty");
            return;
        }

        setFirstNameErrors("");
        setLastNameErrors("");
        setUsernameErrors("");
        setPasswordErrors("");
    }

    return (
        <div className="login-container2" >
            <span className="login-text">Create Account</span>

            <div>
                <button className="login-button button"
                    onClick={() => props.setContinueWithEmail(false)}>
                    Back
                </button>
            </div>
            
            <div className="email-submission-container">
                <input
                    type="text"
                    placeholder="First Name"
                    className="input"
                    onChange={(event) => {
                        setFirstName(event.target.value);
                    }}
                    value={firstName} />
                <span className="email-submission-errors">{firstNameErrors}</span>
            </div>

            <div className="email-submission-container">
                <input
                    type="text"
                    placeholder="Last Name"
                    className="input"
                    onChange={(event) => {
                        setLastNameErrors(event.target.value);
                    }}
                    value={lastName} />
                <span className="email-submission-errors">{lastNameErrors}</span>
            </div>

            <div className="email-submission-container">
                <input
                    type="text"
                    placeholder="Username"
                    className="input"
                    onChange={(event) => {
                        setUsername(event.target.value);
                    }}
                    value={username}/>
                <span className="email-submission-errors">{usernameErrors}</span>
            </div>

            <div className="password-submission-container">
                <input
                    type="text"
                    placeholder="Password"
                    className="input"
                    onChange={(event) => {
                        setPassword(event.target.value);
                    }}
                    value={password}/>
                <span className="email-submission-errors">{passwordErrors}</span>
            </div>
            <div className="email-login-container">
                <button className="login-button button"
                        onClick={() => register()}>
                    Register
                </button>
            </div>
        </div>
    )
}

export default Register