import React from 'react';
import Navbar from '../../components/NavBar/navbar.js';
import WaitlistComponent from '../../components/Waitlist/waitlist.js';
import Footer from '../../components/Footer/footer.js';
import AccountManagementComponent from '../../components/Account/account-management.js';
import './style.css'

const AccountManagement = (props) => {
    return (
        <div className="account-management">
            <div className="account-management-page-body">
                <Navbar />
                <AccountManagementComponent />
                <Footer/>
            </div>
        </div>
    )
}

export default AccountManagement