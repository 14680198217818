import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './style.css';

import LoginButton from "../Login/login-button";
import Popup from '../Popup/popup.js';
import AccountMenu from './account-menu.js';

import { getAccount } from '../../data/account-data-access.js';

const AccountIcon = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const navigate = useNavigate();

    const [menuOpen, setMenuOpen] = useState(false);
    const [activeUser, setActiveUser] = useState();

    document.body.style.overflow = menuOpen ? "hidden" : "unset";

    useEffect(() => {
        const load = async () => {
            var user = await getUser();

            //Need to do something here to navigate the user to an account additional info page if they are missing account data
            //if(!user?.IsDataFilled ?? false)
            //navigate("finishaccount")
        }

        load();
    }, [user]);

    const getUser = async () => {
        if (!isAuthenticated || isLoading)
            return;

        var res = await getAccount(user.email);

        setActiveUser(res);

        return res;
    }

    if (isLoading || !isAuthenticated) {
        return <LoginButton/>
    }

    return (
        isAuthenticated && (
            <div className="icon-container">
                <button className="account-icon-button" onClick={() => setMenuOpen(true)}>
                    <img src={user.picture}
                        alt={user.name}
                        className="account-icon" />
                </button>
                <Popup trigger={menuOpen}
                        setTrigger={setMenuOpen}
                        className="account-menu-style"
                        contentClassName="account-menu-content-style"
                        showCloseButton={false}>
                    <AccountMenu user={user} />
                </Popup>
            </div>
        )
    )
};

export default AccountIcon;
