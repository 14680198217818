import React from 'react'

import Navbar from '../../components/NavBar/navbar.js'
import Waitlist from '../../components/Waitlist/waitlist.js'
import Footer from '../../components/Footer/footer.js'
import './style.css'

const NotFound = (props) => {
    return (
        <div className="not-found-container">
            <Navbar></Navbar>
            <div className="not-found-container1">
                <h1 className="not-found-text1">404</h1>
                <h2 className="not-found-text2">
                    The page you requested was not found.
                </h2>
            </div>
            <Footer rootClassName="footer-root-class-name1"></Footer>
        </div>
    )
}

export default NotFound