import React from 'react'

import Navbar from '../../components/NavBar/navbar.js'
import Waitlist from '../../components/Waitlist/waitlist.js'
import Footer from '../../components/Footer/footer.js'
import './style.css'

const Blog = (props) => {
    return (
        <div className="blog-container">
            <div className="blog-container1">
                <Navbar></Navbar>
                <Waitlist rootClassName="waitlist-root-class-name"></Waitlist>
                <Footer rootClassName="footer-root-class-name1"></Footer>
            </div>
        </div>
    )
}

export default Blog
