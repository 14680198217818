import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment'
import { useSearchParams } from "react-router-dom";

import Navbar from '../../components/NavBar/navbar.js';
import Footer from '../../components/Footer/footer.js';
import Sidebar from '../../components/Sidebar/sidebar.js';
import Popup from '../../components/Popup/popup.js';
import Loading from '../../components/Loading/loading.js';
import './style.css'
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { getAccount } from '../../data/account-data-access.js';
import { getVotesByAccount, getVotesByGroupId, getPollById, createVote, castVote, updatePoll, removePoll, removeVotingOptionAttribute } from '../../data/vote-data-access';
import { getGroups, createGroup, addGroupMember, removeGroupMember } from '../../data/group-data-access';

const Votes = (props) => {
    const { user, isAuthenticated, isAuthLoading } = useAuth0();
    const [searchParams, setSearchParams] = useSearchParams();
    const [polls, setPolls] = useState(null);
    const [selectedPoll, setSelectedPoll] = useState();
    const [account, setAccount] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const load = async () => {
            var loadedAccount = await getUser();

            if (!loadedAccount)
                return;

            await loadPolls(loadedAccount.Id);
        }

        load();
    }, [user]);

    useEffect(() => {
        if (polls)
            setIsLoading(false);

    }, [polls])

    useEffect(() => {
        if (!selectedPoll)
            return;

        setSearchParams({
            poll: selectedPoll.Id
        })
    }, [selectedPoll])

    const loadPolls = async (accountId) => {
        setPolls(null);
        var res = await getVotesByAccount(accountId);
        setPolls(res);

        if (searchParams) {
            var pollIdFromUrl = searchParams.get("poll");
            var idx = res.findIndex((poll) => poll.Id == pollIdFromUrl);
            setSelectedPoll(res[idx]);
        }
    }

    const getUser = async () => {
        if (!isAuthenticated || isAuthLoading)
            return;

        var res = await getAccount(user.email);

        setAccount(res);

        return res;
    }

    var votesContent = () => {
        if (isLoading) {
            return <Loading />
        }
        else {
            return (
                <div className="votes-container3">
                    <VoteList polls={polls}
                        setSelectedPoll={setSelectedPoll}
                        selectedPoll={selectedPoll}
                        account={account}
                        loadPolls={loadPolls} />
                    <VotesBody account={account}
                        poll={selectedPoll}
                        loadPolls={loadPolls}/>
                </div>
            )
        }
    }

    return (
        <div className="votes-container">
            <div className="votes-container1">
                <Navbar/>
                <div className="votes-container2">
                    <Sidebar votesSelected={true} />
                    {
                        votesContent()
                    }
                </div>
                <Footer />
            </div>
        </div>
    )
}

const VoteList = (props) => {
    const [polls, setPolls] = useState(props.polls);
    const [addNewVoteOpen, setAddNewVoteOpen] = useState(false);

    useEffect(() => {
        setPolls(props.polls);
    }, [props.polls]);

    useEffect(() => {
        if (props.selectedPoll || !props.polls || props.polls.length === 0)
            return;

        props.setSelectedPoll(props.polls[0])
    })

    var hasVotes = polls && polls.length > 0;

    return (
        <div className="vote-list-container">
            <Popup trigger={addNewVoteOpen}
                setTrigger={setAddNewVoteOpen}
                className="vote-list-add"
                contentClassName="add-new-vote-popup-content"
                showCloseButton={true}>
                <CreateVotePopupContent accountId={props.account?.Id} loadPolls={props.loadPolls} />
            </Popup>
            <div className="vote-list-header">
                <h1 className="vote-list-header-text">Votes</h1>
                <div className="vote-list-header-controls">
                    <svg viewBox="0 0 1024 1024"
                        className="vote-list-controls-filter-icon">
                        <path d="M256 554v-84h512v84h-512zM128 256h768v86h-768v-86zM426 768v-86h172v86h-172z"></path>
                    </svg>
                    <div className="add-new-vote-button-container">
                        <svg viewBox="0 0 1024 1024"
                            className="vote-list-controls-add-icon"
                            onClick={() => setAddNewVoteOpen(true)}>
                            <path d="M726 470v-86h-172v-170h-84v170h-172v86h172v170h84v-170h172zM938 170v768l-170-170h-598q-34 0-59-26t-25-60v-512q0-34 25-59t59-25h684q34 0 59 25t25 59z"></path>
                        </svg>
                    </div>

                </div>
            </div>
            <div className="vote-list-body">
                {
                    !hasVotes &&
                    <span className="no-content-message">There are no votes to show</span>
                }
                {
                    hasVotes &&
                    polls.map((c, idx) => <VoteListItem key={idx}
                        poll={c}
                        selectedPoll={props.selectedPoll}
                        setSelectedPoll={(poll) => props.setSelectedPoll(poll)} />)
                }
            </div>
        </div>
    )
}

const VoteListItem = (props) => {
    const isSelected = props.selectedPoll?.Id === props.poll.Id;

    var voteName = props.poll?.Name ?? "New Vote";

    if (voteName > 50)
        voteName = voteName.slice(0, 50) + "...";

    var groupName = props.poll?.Group?.GroupName ?? "";

    if (groupName > 50)
        groupName = groupName.slice(0, 50) + "...";

    return (
        <div className="vote-list-item-container"
            style={{ backgroundColor: isSelected ? "var(--dl-color-gray-700)" : "unset" }}
            onClick={() => props.setSelectedPoll(props.poll)}>
            <div className="vote-list-item-container1">
                <div className="vote-list-item-container2">
                    <div>
                        <h1 className="vote-list-item-name">
                            {voteName}
                        </h1>
                        <h3 className="vote-list-item-group-name">
                            {groupName}
                        </h3>
                    </div>

                    <svg viewBox="0 0 1024 1024"
                        className="vote-list-item-arrow-icon">
                        <path d="M250 176l92-90 426 426-426 426-92-90 338-336z"></path>
                    </svg>
                </div>

            </div>
        </div>
    )
}

const CreateVotePopupContent = (props) => {
    const [voteName, setVoteName] = useState("");
    const [expiresOn, setExpiresOn] = useState(new Date());
    const [selectedGroupId, setSelectedGroupId] = useState(-1);
    const [userGroups, setUserGroups] = useState([]);
    const [newGroupMembers, setNewGroupMembers] = useState([]);
    const [newGroupMember, setNewGroupMember] = useState("");

    useEffect(() => {
        const loadUserGroups = async () => {
            var res = await getGroups(props.accountId);

            setUserGroups(res);
        }
        loadUserGroups();
    }, [props.accountId]);

    const handleInput = e => {
        if (e.target.value === '\n') {
            return;
        }

        setNewGroupMember(e.target.value);
    }

    const addOnEnter = e => {
        if (e.key === 'Enter') {
            setNewGroupMembers([...newGroupMembers, newGroupMember])
            setNewGroupMember("");
        }
    }

    const create = async () => {
        //need to show a "vote name must be set or something stupid"
        if (voteName?.trim().length === 0)
            return;

        var groupId = selectedGroupId == -1
            ? null
            : selectedGroupId;

        var request = {
            GroupId: groupId,
            CreatedByAccountId: props.accountId,
            Name: voteName,
            NewGroupMembers: newGroupMembers
        }

        var result = await createVote(request);

        //need to show something to the user here
        if (!result)
            return;

        await props.loadPolls(props.accountId);
    }

    var userGroupOptions = "";
    if (userGroups.length > 0) {
        userGroupOptions = userGroups.map((userGroup, index) =>
            <option value={`${userGroup.Id}`}>{userGroup.GroupName}</option>
        );
    }

    var newGroupMembersList = "";
    if (newGroupMembers.length > 0) {
        newGroupMembersList = newGroupMembers.map((newGroupMember, index) =>
            <NewGroupMemberName name={newGroupMember} key={index} />
        );
    }

    return (
        <div className="create-vote-popup">
            <p>Create new Vote</p>

            <input
                type="text"
                placeholder="Name..."
                className="create-vote-popup-input"
                onChange={(event) => {
                    setVoteName(event.target.value)
                }}
                value={voteName}
            />

            <label className="create-vote-popup-input-label">
                Vote Expiration:
                <input type="date"
                    value={expiresOn}
                    className="create-vote-popup-input"
                    onChange={e => setExpiresOn(moment(new Date(e.target.value)).format('YYYY-MM-DD'))} />
            </label>

            <label className="create-vote-popup-input-label">
                Group:
                <select className="create-vote-popup-group-select"
                    onChange={(event) => {
                        setSelectedGroupId(parseInt(event.target.value))
                    }}
                    defaultValue="Create new group">
                    <option value="-1">Create new group</option>
                    {
                        userGroupOptions
                    }
                </select>
            </label>

            {selectedGroupId == -1 &&
                <div className="create-vote-popup-new-group-container">
                    <label className="create-vote-popup-input-label">
                        Invite your friends:
                        <input type="text"
                            placeholder="Email or name..."
                            className="create-vote-popup-input"
                            value={newGroupMember}
                            onChange={handleInput}
                            onKeyDown={addOnEnter}
                        />
                    </label>

                    <div className="create-vote-popup-new-group-members-container">
                        {
                            newGroupMembersList
                        }
                    </div>
                </div>
            }
            <button className="create-vote-popup-button button"
                onClick={() => create()}>
                <span>Create</span>
            </button>
        </div>
    )
}

const VotesHeader = (props) => {
    const [editingPollName, setEditingPollName] = useState(false);
    const [pollName, setPollName] = useState(props.poll?.Name);

    //Need to include an "Active" or "Complete" label after a poll name
    //Active label should have a expires on label following if an expiration exists

    useEffect(() => {
        //loadChannelMessages();
        setPollName(props.poll?.Name);
        setEditingPollName(false);
    }, [props.poll]);

    useEffect(() => {
        givePollNameTextboxFocus();
    }, [editingPollName])

    const toggleEditingPollName = () => {
        setEditingPollName(!editingPollName)
    }

    const handlePollNameChanged = e => {
        setPollName(e.target.value)
    }

    const savePollNameOnEnter = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            savePollName();
        }
    }

    const savePollName = async () => {
        if (pollName === props.poll?.Name) {
            setEditingPollName(false);
            return;
        }

        var request = {
            ...props.poll,
            Name: pollName
        }

        var result = await updatePoll(request);

        //need to show something to the user here
        if (!result)
            return;

        await props.loadPolls(props.account.Id);

        setEditingPollName(false);
    }

    const givePollNameTextboxFocus = () => {
        var textArea = document.getElementById("edit-poll-name-textarea");
        textArea?.focus()
        textArea?.setSelectionRange(textArea.value.length, textArea.value.length);
    }

    var pollNameField = <h1 className="vote-poll-name1">
        <span>{props.poll?.Name}</span>
    </h1>;

    if (editingPollName) {
        pollNameField = <textarea className="edit-poll-name-textarea textarea"
            onChange={handlePollNameChanged}
            value={pollName}
            onKeyDown={savePollNameOnEnter}
            onBlur={savePollName}
            id="edit-poll-name-textarea" />
    }

    return (
        <div className="vote-poll-tools">
            <div className="vote-poll-name-container">
                {
                    pollNameField
                }
                <svg viewBox="0 0 1024 1024"
                    className="edit-poll-name-icon"
                    onClick={() => toggleEditingPollName()}>
                    <path d="M864 0c88.364 0 160 71.634 160 160 0 36.020-11.91 69.258-32 96l-64 64-224-224 64-64c26.742-20.090 59.978-32 96-32zM64 736l-64 288 288-64 592-592-224-224-592 592zM715.578 363.578l-448 448-55.156-55.156 448-448 55.156 55.156z"></path>
                </svg>            </div>
            <div className="vote-poll-tools-container">
                <svg
                    viewBox="0 0 804.5714285714286 1024"
                    className="vote-add-poll-option"
                >
                    <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                </svg>
                <svg
                    viewBox="0 0 1024 1024"
                    className="vote-poll-settings"
                >
                    <path d="M512 662q62 0 106-44t44-106-44-106-106-44-106 44-44 106 44 106 106 44zM830 554l90 70q14 10 4 28l-86 148q-8 14-26 8l-106-42q-42 30-72 42l-16 112q-4 18-20 18h-172q-16 0-20-18l-16-112q-38-16-72-42l-106 42q-18 6-26-8l-86-148q-10-18 4-28l90-70q-2-14-2-42t2-42l-90-70q-14-10-4-28l86-148q8-14 26-8l106 42q42-30 72-42l16-112q4-18 20-18h172q16 0 20 18l16 112q38 16 72 42l106-42q18-6 26 8l86 148q10 18-4 28l-90 70q2 14 2 42t-2 42z"></path>
                </svg>
            </div>
        </div>
    )
}

const VotesBody = (props) => {
    const [poll, setPoll] = useState(props.poll);

    var content = <NoPollSelectedContent />;

    useEffect(() => {
        loadPoll();
    }, [props.poll]);

    const loadPoll = async () => {
        if (props.poll?.Id === undefined || props.poll?.Id === null)
            return;

        var res = await getPollById(props.poll?.Id)

        if (!res)
            return;

        setPoll(res);
    }

    if (poll)
    {
        var pollIsComplete = poll.Complete ?? (poll.TotalVotesCast >= poll.TotalVotes && poll.TotalVotesCast !== 0);

        const userHasSubmittedVote = () => {
            return poll.Votes?.some((vote) => vote.CreatedBy === props.account.Id) ?? false;
        }

        console.log(userHasSubmittedVote())

        content = pollIsComplete || userHasSubmittedVote()
            ? <PollResultsContent poll={props.poll} pollIsComplete={pollIsComplete}/>
                : <ActiveVoteContent account={props.account} poll={poll} loadPolls={props.loadPolls} />
    }

    return (
        <div className="vote-poll-content">
            <VotesHeader account={props.account} poll={props.poll} loadPolls={props.loadPolls} />
            {
                content
            }
        </div>
    )
}

//if we go down the path of only allowing the vote creator to start the vote
const StartVotePage = (props) => {
    return (
        <div className="vote-poll-content">
        </div>
    );
}
const VoteNotStartedPage = (props) => {
    return (
        <div className="vote-poll-content">
        </div>
    );
}

const NoPollSelectedContent = (props) => {
    return (
        <div className="vote-poll-content">
        </div>
    );
}

//We will need to have an end vote button somewhere
const ActiveVoteContent = (props) => {
    const [newVoteOptionOpen, setNewVoteOptionOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState();

    const hasVotingOptions = props.poll?.VotingOptions && props.poll?.VotingOptions?.length > 0;
    const voteSubmitEnabled = selectedOption && selectedOption.Id >= 0;
    const voteSubmitClass = voteSubmitEnabled
        ? "vote-submit"
        : ".vote-submit .disabled";

    const submit = async () => {
        if (!voteSubmitEnabled)
            return;

        var request = {
            CreatedBy: props.account?.Id,
            SelectedOptionId: selectedOption?.Id
        }

        var res = await castVote(request, props.poll?.Id)

        if (!res)
            return;

        await props.loadPolls(props.account?.Id);
    }

    return (
        <div>
            <Popup trigger={newVoteOptionOpen}
                setTrigger={setNewVoteOptionOpen}
                className="vote-list-add"
                contentClassName="add-new-vote-popup-content"
                showCloseButton={true}>
                <CreateVotingOptionPopupContent accountId={props.account?.Id} poll={props.poll} loadPolls={props.loadPolls} />
            </Popup>

            {
                !hasVotingOptions &&
                <span className="no-content-message">There are no voting options to show</span>
            }

            <div className="vote-poll-options">
                {
                    hasVotingOptions &&
                    props.poll?.VotingOptions?.map((c, idx) => <VoteOptionCard key={idx}
                        option={c}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption} />)
                }
                <svg
                    viewBox="0 0 1024 1024"
                    className="vote-add-poll-option1"
                    onClick={() => setNewVoteOptionOpen(true)}>
                    <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
            </div>
            <div className="vote-poll-decision-container">
                <div className={voteSubmitClass}
                    onClick={submit}>
                    <h3 className="vote-submit-text">Submit</h3>
                </div>
                <div className="vote-abstain">
                    <h3 className="vote-abstain-text">Abstain</h3>
                </div>
            </div>
        </div>
    )
}

const VoteOptionCard = (props) => {
    const className = props.selectedOption?.Id === props.option?.Id
        ? "vote-poll-option-selected"
        : "vote-poll-option";

    //will need to read the attributes at some point

    return (
        <div className={className}
             onClick={() => props.setSelectedOption(props.option)}>
            <img
                alt="image"
                src="https://play.teleporthq.io/static/svg/default-img.svg"
                className="vote-image"
            />
            <h3>{props.option?.Name}</h3>
            <span>Option Description</span>
            <span>Option price?</span>
        </div>
    )
}

const CreateVotingOptionPopupContent = (props) => {
    const [optionName, setOptionName] = useState("");

    const create = async () => {
        //need to show a "vote name must be set or something stupid"
        if (optionName?.trim().length === 0)
            return;

        var request = {
            ...props.poll,
            VotingOptions: [
                {
                    Name: optionName
                }
            ]
        }

        var result = await updatePoll(request);

        //need to show something to the user here
        if (!result)
            return;

        await props.loadPolls(props.accountId);
    }

    return (
        <div className="create-vote-popup">
            <p>Create new Option</p>

            <input
                type="text"
                placeholder="Name..."
                className="create-vote-popup-input"
                onChange={(event) => {
                    setOptionName(event.target.value)
                }}
                value={optionName}
            />

            <button className="create-vote-popup-button button"
                onClick={() => create()}>
                <span>Create</span>
            </button>
        </div>
    )
}

const PollResultsContent = (props) => {

    //we are going to need to do some weird shit to handle ties here

    var winningOptionHeaderText = props.pollIsComplete ?? false
        ? "Here&apos;s the winner:"
        : "Here&apos;s the winner so far:";

    return (
        <div>
            <div className="vote-results-poll-results-container">
                <div className="vote-results-poll-winner">
                    <h2 className="vote-results-text1">
                        {winningOptionHeaderText}
                    </h2>
                    <div className="vote-results-poll-option">
                        <img
                            alt="image"
                            src="https://play.teleporthq.io/static/svg/default-img.svg"
                            className="vote-results-image"
                        />
                        <h3>Option Name</h3>
                        <span>Option Description</span>
                        <span>Option price?</span>
                    </div>
                </div>
                <div className="vote-results-vote-distributions">
                    <VoteResultsListItem />
                </div>
            </div>
            <div className="vote-results-poll-decision-container">
                <div className="vote-results-recast">
                    <h3 className="vote-results-recast-text">
                        Recast Vote
                    </h3>
                </div>
            </div>
        </div>
    )
}

const VoteResultsListItem = (props) => {
    return (
        <div className="vote-results-vote-results-wrapper2">
            <div className="vote-results-poll-option3">
                <h3>Option Name</h3>
                <span>Option Description</span>
                <span>Option price?</span>
            </div>
            <div className="vote-results-vote-results-container2">
                <div className="vote-results-percentage-of-vote2">
                    <span className="vote-results-text4">
                        20%: 4 / 10 Votes
                    </span>
                </div>
                <div className="vote-results-vote-tally-container2">
                    <div className="vote-results-vote-tally4"></div>
                    <div className="vote-results-vote-tally5"></div>
                </div>
            </div>
        </div>
    )
}

const NewGroupMemberName = (props) => {
    return (
        <div className="new-group-member-name-container">
            <p className="new-group-member-name">{props.name}</p>
            <svg viewBox="0 0 1024 1024" className="new-group-member-name-delete-icon">
                <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM353.835 414.165l97.835 97.835-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l97.835-97.835 97.835 97.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-97.835-97.835 97.835-97.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-97.835 97.835-97.835-97.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
            </svg>
        </div>
    )
}


export default Votes