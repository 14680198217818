import React from 'react'

import './style.css'

const Contact = (props) => {
    return (
        <div className="contact-container">
            <div className="contact-contact">
                <div className="contact-contact-body">
                    <div className="contact-contact-form">
                        <h1 className="contact-title">Say Hello!</h1>
                        <div className="contact-name-input">
                            <input type="text"
                                   placeholder="first name..."
                                   className="input contact-textinput"/>
                            <input type="text"
                                   placeholder="last name..."
                                   className="input contact-textinput1"/>
                        </div>
                        <div className="contact-email-reason-input">
                            <input type="text"
                                   placeholder="email..."
                                   className="input contact-textinput2"/>
                            <select className="contact-select">
                                <option value="">please choose an option...</option>
                                <option value="Option 2">Information</option>
                                <option value="Option 1">Work With Us</option>
                                <option value="Option 3">Other</option>
                            </select>
                        </div>
                        <div className="contact-text-input">
                            <textarea
                                placeholder="message..."
                                className="textarea contact-textarea">
                            </textarea>
                        </div>
                        <div className="contact-submit-container">
                            <button type="button" className="button contact-button">
                                Send Message
                            </button>
                        </div>
                    </div>
                    <div className="contact-contact-info">
                        <h1 className="contact-contact-information">Contact Information</h1>
                        <span className="contact-text">Mailing Address: </span>
                        <span className="contact-text1">Phone Number: </span>
                        <span className="contact-text2">Contact Hours: </span>
                        <h1>
                            <span>Follow Us</span>
                            <br></br>
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact

